import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { InputAdornment, TextField } from "@mui/material";

import axios from "axios";
import {
  sliderDesktopImages,
  sliderMobileImages,
  sliderPost,
  sliderUpdate,
  updateSliderDeskImage,
  updateSliderMobileImage,
  validateSliderUrl,
} from "../../redux/slider/sliderSlice";
import { useNavigate, useParams } from "react-router-dom";

const UpdateSlider = () => {
  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { categoryList } = useSelector((store) => store.category);
  const {
    mobileimage,
    desktopimage,
    mobileimageLoading,
    desktopimageLoading,
    slidertotal,
  } = useSelector((store) => store.Sliders);

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [sliderId, setSliderId] = useState("");
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [checkurl, setCheckurl] = useState("");
  const [superCategory, setSuperCategory] = useState("");
  const [superCategoryId, setSuperCategoryId] = useState("");
  const [catbySupercat, setCatbySupercat] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [nameError, setNameError] = useState("");

  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");

  useEffect(() => {
    const sliderUrl = params.slugUrl;
    const updateslider = slidertotal.find((slide) => slide.URL === sliderUrl);
    console.log(updateslider, "updateslider");
    setSliderId(updateslider._id);
    setName(updateslider.name);
    setUrl(updateslider.URL);
    setSuperCategory(updateslider.superCategory);
    setSuperCategoryId(updateslider.superCategoryId);
    setCategory(updateslider.category);
    setCategoryId(updateslider.categoryId);
    dispatch(updateSliderMobileImage(updateslider.MblImg));
    dispatch(updateSliderDeskImage(updateslider.DeskImg));
  }, [params.slugUrl]);

  const handleSuperCatgory = (e) => {
    setSuperCategoryId(e.target.value);
    const getSuperCategoryName = superCatTotal.find(
      (indCat) => indCat._id === e.target.value
    );
    if (getSuperCategoryName) {
      setSuperCategory(getSuperCategoryName);
    }
  };

  useEffect(() => {
    const catbysupercat = categoryList.filter(
      (cat) => cat.superCategoryId === superCategoryId
    );
    setCatbySupercat(catbysupercat);
  }, [superCategoryId, categoryList]);

  const handleCatgory = (e) => {
    setCategoryId(e.target.value);
    const getCategoryName = categoryList.find(
      (indCat) => indCat._id === e.target.value
    );
    if (getCategoryName) {
      setCategory(getCategoryName);
    }
  };

  const desktopChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(sliderDesktopImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const mobileChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(sliderMobileImages({ mobileImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const verifyurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setCheckurl("");
    const sliderurl = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setUrl(sliderurl);

    if (sliderurl !== "") {
      let responce = await dispatch(validateSliderUrl(sliderurl));
      if (responce.payload.success) {
        setError("Slider already exist");
        setErrorcolor("red");
      } else if (sliderurl.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckurl("");
      } else if (sliderurl.length >= 3) {
        setErrorcolor(" ");
        setError("");
        setCheckurl(sliderurl);
      }
    }
  };

  const handleData = async () => {
    const formData = {
      name: name,
      URL: url,
      superCategoryId: superCategoryId,
      superCategory: superCategory.name,
      superCategoryUrl: superCategory.slugUrl,
      categoryId: categoryId,
      category: category.name,
      categoryUrl: category.slugUrl,
      DeskImg: desktopimage,
      MblImg: mobileimage,
      sliderid: sliderId,
    };
    const response = await dispatch(sliderUpdate(formData));
    if (response.payload.success) {
      alert(`SubCategory With ${name} Updated.`);
      navigate("/admin/listslider");
      setName("");
      setSuperCategory("");
      setSuperCategoryId("");
      setCategory("");
      setCategoryId("");
      const formData = {
        status: true,
        image: "",
      };
      await dispatch(updateSliderMobileImage(formData));
      await dispatch(updateSliderDeskImage(formData));
    }
  };

  const handleReset = async () => {
    setName("");
    setSuperCategory("");
    setSuperCategoryId("");
    setCategory("");
    setCategoryId("");
    const formData = {
      status: true,
      image: "",
    };
    await dispatch(updateSliderMobileImage(formData));
    await dispatch(updateSliderDeskImage(formData));
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
              >
                Add Slider
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Super Category:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <div style={{ width: "100%" }}>
                        <Select
                          labelId="super-category-label"
                          className="textfield"
                          label="Super Category"
                          id="demo-simple-select"
                          value={superCategoryId}
                          inputProps={{ style: { textAlign: "center" } }}
                          onChange={(e) => handleSuperCatgory(e)}
                        >
                          {superCatTotal &&
                            superCatTotal.map((indCat, index) => (
                              <MenuItem key={index} value={indCat._id}>
                                {indCat.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Category:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <div style={{ width: "100%" }}>
                        <Select
                          labelId="Category"
                          className="textfield"
                          label="Category"
                          id="demo-simple-select"
                          value={categoryId}
                          inputProps={{ style: { textAlign: "center" } }}
                          onChange={(e) => handleCatgory(e)}
                        >
                          {catbySupercat &&
                            catbySupercat.map((indCat, index) => (
                              <MenuItem key={index} value={indCat._id}>
                                {indCat.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Slider Name :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Name"
                        variant="outlined"
                        className={
                          nameError !== ""
                            ? "textfield error_class"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        value={name}
                        onChange={(e) => verifyurl(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="vehicleWeight"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Desktop Image :
                    </label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="file"
                        className={
                          "block rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 "
                        }
                        onChange={desktopChange}
                      />
                    </div>
                  </div>
                  {desktopimageLoading ? (
                    <></>
                  ) : (
                    <div>
                      <img
                        src={desktopimage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    </div>
                  )}
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="vehicleWeight"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Mobile Image :
                    </label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="file"
                        className={
                          "block rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 "
                        }
                        onChange={mobileChange}
                      />
                    </div>
                  </div>
                  {mobileimageLoading ? (
                    <></>
                  ) : (
                    <div>
                      <img
                        src={mobileimage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            <button
              style={{ background: "#ef4444", outline: "none", border: "none" }}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              onClick={() => handleReset()}
            >
              <i className="ph-bold ph-x text-lg" /> Reset
            </button>
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{ background: "#4D44B5", outline: "none", border: "none" }}
              onClick={() => handleData()}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateSlider;
