import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { MenuItem, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { updateOrder } from "../../redux/Order/OrderSlice";

const AssignOrder = () => {
  const { deliveryPersions } = useSelector((store) => store.Employees);

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [deliveryname, setDeliveryname] = useState("");
  const [deliveryMobile, setDeliveryMobile] = useState("");
  const [delivery_id, setDelivery_id] = useState("");
  const [selectOrder, setSelectOrder] = useState("");

  useEffect(() => {
    const orderId = params.orderid;
    const fetchData = async () => {
      try {
        const url = `${Baseurl}/api/v1/order/singleorderbyclient/${orderId}`;
        const orderFetch = await axios.get(url);
        try {
          if (orderFetch.data.success) {
            const orderdetails = orderFetch.data.order;
            setSelectOrder(orderdetails);
          }
        } catch (error) {}
      } catch (error) {}
    };
    fetchData();
    setInterval(async () => {
      fetchData();
    }, 500);
  }, [params.orderid]);

  const handlesubmit = async (e) => {
    e.preventDefault();

    var date = new Date();

    date.setDate(date.getDate());
    if (selectOrder.hasOwnProperty("OrderprocessDate")) {
      var OrderprocessDate = {
        OrderprocessingDate: selectOrder.OrderprocessDate.OrderprocessingDate,
        OrderDispatchedDate: selectOrder.OrderprocessDate.OrderDispatchedDate,
        OutfordeliveryDate: date,
        OrderDeliveredDate: selectOrder.OrderprocessDate.OrderDeliveredDate,
        OrderCancelledDate: selectOrder.OrderprocessDate.OrderCancelledDate,
      };
    } else {
      var OrderprocessDate = {};
    }

    if (deliveryMobile !== "" && deliveryname !== "") {
      const formData = {
        Status: 4,
        StatusText: "Out for Delivery",
        orderid: selectOrder._id,
        Delivery: {
          DName: deliveryname,
          DMobile: deliveryMobile,
        },
        remark: {
          ...selectOrder.remark,
          OutfordeliveryRemark: "Your item is out for delivery",
        },
        OrderprocessDate: OrderprocessDate,
      };
      const data = await dispatch(updateOrder(formData));
      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          setDeliveryname("");
          setDeliveryMobile("");
          const sendOptthowwp = async () => {
            const name = selectOrder.ClientName;
            const mobile = selectOrder.Mobile;
            const orderId = selectOrder._id.slice(18).toUpperCase();
            let data = JSON.stringify({
              countryCode: "+91",
              phoneNumber: mobile,
              callbackData: "some text here",
              type: "Template",
              template: {
                name: "order_shipped",
                languageCode: "en",
                bodyValues: [name, orderId],
              },
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: "https://api.interakt.ai/v1/public/message/",
              headers: {
                Authorization:
                  "Basic djlWdldPY1habHlBbEtoZkFFUl8zVWdQTDAycmJqWl9BWHd4dDF4eHVjMDo=",
                "Content-Type": "application/json",
              },
              data: data,
            };

            axios
              .request(config)
              .then((response) => {
                // console.log(JSON.stringify(response.data));
              })
              .catch((error) => {
                console.log(error);
              });
          };
          sendOptthowwp();
          navigate("/admin/order/dispatchedOrder");
        } else {
        }
      }
    } else {
      alert("please enter correct values");
    }
  };

  const selectDeliveryPersion = (e) => {
    setDelivery_id(e.target.value);
    const getdeliveryperson = deliveryPersions.find(
      (indCat) => indCat._id === e.target.value
    );
    if (getdeliveryperson) {
      setDeliveryMobile(getdeliveryperson.Dmobile);
      setDeliveryname(getdeliveryperson.Dname);
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
              >
                ASSINGN DELIVERY BOY & DISPATCH ORDER
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      DELIVERY BOY:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <div style={{ width: "100%" }}>
                        <Select
                          labelId="super-category-label"
                          className="textfield"
                          label="DELIVERY BOY"
                          id="demo-simple-select"
                          inputProps={{ style: { textAlign: "center" } }}
                          value={delivery_id}
                          onChange={(e) => selectDeliveryPersion(e)}
                        >
                          {deliveryPersions &&
                            deliveryPersions.map((indCat, index) => (
                              <MenuItem key={index} value={indCat._id}>
                                {indCat.Dname}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{
                background: "#4D44B5",
                outline: "none",
                border: "none",
              }}
              onClick={(e) => handlesubmit(e)}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignOrder;
