import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { MenuItem, Typography } from "@mui/material";
import { updateOrder } from "../../redux/Order/OrderSlice";

const UpdatePayment = () => {
  const { ordertotal, isOrderLoading } = useSelector((store) => store.order);
  const dispatch = useDispatch();

  const [paymentmode, setPaymentMode] = useState("");
  const [paymentstatus, setPaymentstatus] = useState("");
  const [currentpaymentstatus, setCurrentPaymentstatus] = useState("");
  const [nettotal, setNettotal] = useState("");
  const [orderId, setorderId] = useState("");
  const [orderDetails, setorderDetails] = useState("");
  const [order, setorder] = useState("");
  const [updateBoxShow, setUpdateBoxShow] = useState(false);
  const [search, setSearch] = useState("");
  const [netPayble, setNetPayble] = useState(0);
  const [orderStatus, setOrderStatus] = useState("");
  const [orderText, setOrderText] = useState("");

  const paymentMode = [
    { name: "COD", id: 1 },
    { name: "PayTM", id: 3 },
    { name: "PayuMoney", id: 10 },
    { name: "RazorPay", id: 2 },
    { name: "Razorpay link", id: 4 },
    { name: "Payment link", id: 5 },
    { name: "PhonePe", id: 6 },
    { name: "Google Pay", id: 7 },
    { name: "Other Wallet", id: 8 },
    { name: "Other Payment Mode", id: 9 },
  ];

  const currentPayment = [
    { name: "Not Paid", id: 1 },
    { name: "Partial Payment", id: 2 },
    { name: "Paid", id: 3 },
    { name: "Failed", id: 4 },
    { name: "Pending", id: 5 },
  ];

  const PaymentStatus = [
    { name: "TXN_SUCCESS", id: 1 },
    { name: "TXN_FAILURE", id: 3 },
    { name: "PENDING", id: 2 },
    { name: "COD", id: 4 },
  ];
  const OrderStatus = [
    { name: "Order Cancelled", status: 0, id: 1 },
    { name: "Order Recieved", status: 1, id: 3 },
    { name: "Order Processing", status: 2, id: 2 },
    { name: "Order Dispatched", status: 3, id: 4 },
    { name: "Out for Delivery", status: 4, id: 5 },
    { name: "Order Delivered", status: 5, id: 6 },
  ];

  const selectpaymentMode = (e) => {
    setPaymentMode(e.target.value);
  };
  const selectPaymentStatus = (e) => {
    setPaymentstatus(e.target.value);
  };
  const selectcurrentPayment = (e) => {
    setCurrentPaymentstatus(e.target.value);
  };
  const selectcurrentOrderStatus = (e) => {
    setOrderText(e.target.value);
    const getStatus = OrderStatus.find(
      (indCat) => indCat.name === e.target.value
    );
    if (getStatus) {
      setOrderStatus(getStatus.status);
    }
  };

  const getOrderDetails = (e) => {
    e.preventDefault();

    const findOrderId = ordertotal.find((order) => {
      return order._id.toLowerCase().slice(18).match(search.toLowerCase());
    });
    const orderIdSl = findOrderId._id.toUpperCase().slice(18);
    setorderDetails(findOrderId);
    setorderId(orderIdSl);
    setPaymentMode(findOrderId.PaymentMode);
    setPaymentstatus(findOrderId.PaymentStatus);
    setCurrentPaymentstatus(findOrderId.CurrentStatus);
    setNetPayble(findOrderId.GrandTotal);
    setOrderStatus(findOrderId.Status);
    setOrderText(findOrderId.StatusText);
    setUpdateBoxShow(true);
  };

  const netPayblechange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setNetPayble(value);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    if (
      orderDetails !== "" &&
      paymentmode !== "" &&
      paymentstatus !== "" &&
      orderStatus !== "" &&
      orderText !== "" &&
      netPayble >= 0 &&
      currentpaymentstatus !== ""
    ) {
      const formData = {
        PaymentMode: paymentmode,
        PaymentStatus: paymentstatus,
        CurrentStatus: currentpaymentstatus,
        GrandTotal: netPayble,
        Status: orderStatus,
        StatusText: orderText,
        orderid: orderDetails._id,
      };
      const data = await dispatch(updateOrder(formData));
      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          setPaymentMode("");
          setPaymentstatus("");
          setNettotal("");
          setorderId("");
          setorder("");
          setCurrentPaymentstatus("");
          setorderDetails("");
          setSearch("");
          setNetPayble("");
          setOrderStatus("");
          setOrderText("");
          setUpdateBoxShow(false);
        } else {
        }
      }
    } else {
      alert("please enter correct values");
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        {isOrderLoading ? (
          <>
            <div className="d-flex justify-content-center loadingMain">
              <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                Order is being fetched
              </h4>
              <BeatLoader
                color={"#36d7b7"}
                loading={isOrderLoading}
                size={10}
                className="mt-2 mx-2"
              />
            </div>
          </>
        ) : (
          <>
            {updateBoxShow ? (
              <>
                <div className="p-2 space-y-6">
                  <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                    <div
                      className="flex items-center justify-between py-2 px-5"
                      style={{
                        backgroundColor: "#4D44B5",
                        color: "#fff",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      <h4
                        className="text-lg font-medium text-default-950 capitalize"
                        style={{
                          color: "#fff",
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                      >
                        Payment Status Update
                      </h4>
                    </div>
                    <div className="p-5 border-t border-dashed border-default-200">
                      <div className="grid md:grid-cols-2 gap-4">
                        <div className="space-y-1">
                          <div className="form-flex">
                            <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: "600", color: "#000066" }}
                            >
                              Order Id :
                            </label>
                            <div
                              className="textfieldcontainer"
                              style={{ width: "80%" }}
                            >
                              <TextField
                                // label="Enter Order Id"
                                variant="outlined"
                                className="textfield"
                                type="text"
                                id="text"
                                value={orderId}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="space-y-1">
                          <div className="form-flex">
                            <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: "600", color: "#000066" }}
                            >
                              Client Name :
                            </label>
                            <div
                              className="textfieldcontainer"
                              style={{ width: "80%" }}
                            >
                              <TextField
                                // label="Enter Order Id"
                                variant="outlined"
                                className="textfield"
                                type="text"
                                id="text"
                                value={orderDetails.ClientName}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="space-y-1">
                          <div className="form-flex">
                            <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: "600", color: "#000066" }}
                            >
                              Client Mobile :
                            </label>
                            <div
                              className="textfieldcontainer"
                              style={{ width: "80%" }}
                            >
                              <TextField
                                // label="Enter Order Id"
                                variant="outlined"
                                className="textfield"
                                type="text"
                                id="text"
                                value={orderDetails.Mobile}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="space-y-1">
                          <div className="form-flex">
                            <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: "600", color: "#000066" }}
                            >
                              Total Amount :
                            </label>
                            <div
                              className="textfieldcontainer"
                              style={{ width: "80%" }}
                            >
                              <TextField
                                // label="Enter Order Id"
                                variant="outlined"
                                className="textfield"
                                type="text"
                                id="text"
                                value={orderDetails.TotalAmount}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="space-y-1">
                          <div className="form-flex">
                            <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: "600", color: "#000066" }}
                            >
                              Net Payble :
                            </label>
                            <div
                              className="textfieldcontainer"
                              style={{ width: "80%" }}
                            >
                              <TextField
                                label="Enter  Net Payble"
                                variant="outlined"
                                className="textfield"
                                type="text"
                                id="text"
                                value={netPayble}
                                onChange={(e) => netPayblechange(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="space-y-1">
                          <div className="form-flex">
                            <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: "600", color: "#000066" }}
                            >
                              Payment Status:
                            </label>
                            <div
                              className="textfieldcontainer"
                              style={{ width: "75%" }}
                            >
                              <div style={{ width: "100%" }}>
                                <Select
                                  labelId="super-category-label"
                                  className="textfield"
                                  label="Payment Status"
                                  id="demo-simple-select"
                                  inputProps={{
                                    style: { textAlign: "center" },
                                  }}
                                  value={paymentstatus}
                                  onChange={(e) => selectPaymentStatus(e)}
                                >
                                  {PaymentStatus &&
                                    PaymentStatus.map((indCat, index) => (
                                      <MenuItem key={index} value={indCat.name}>
                                        {indCat.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="space-y-1">
                          <div className="form-flex">
                            <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: "600", color: "#000066" }}
                            >
                              Payment Mode:
                            </label>
                            <div
                              className="textfieldcontainer"
                              style={{ width: "75%" }}
                            >
                              <div style={{ width: "100%" }}>
                                <Select
                                  labelId="super-category-label"
                                  className="textfield"
                                  label="Payment Mode"
                                  id="demo-simple-select"
                                  inputProps={{
                                    style: { textAlign: "center" },
                                  }}
                                  value={paymentmode}
                                  onChange={(e) => selectpaymentMode(e)}
                                >
                                  {paymentMode &&
                                    paymentMode.map((indCat, index) => (
                                      <MenuItem key={index} value={indCat.name}>
                                        {indCat.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="space-y-1">
                          <div className="form-flex">
                            <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: "600", color: "#000066" }}
                            >
                              Currect Payment Status:
                            </label>
                            <div
                              className="textfieldcontainer"
                              style={{ width: "75%" }}
                            >
                              <div style={{ width: "100%" }}>
                                <Select
                                  labelId="super-category-label"
                                  className="textfield"
                                  label="Currect Payment Status"
                                  id="demo-simple-select"
                                  inputProps={{
                                    style: { textAlign: "center" },
                                  }}
                                  value={currentpaymentstatus}
                                  onChange={(e) => selectcurrentPayment(e)}
                                >
                                  {currentPayment &&
                                    currentPayment.map((indCat, index) => (
                                      <MenuItem key={index} value={indCat.name}>
                                        {indCat.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="space-y-1">
                          <div className="form-flex">
                            <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: "600", color: "#000066" }}
                            >
                              Order Status:
                            </label>
                            <div
                              className="textfieldcontainer"
                              style={{ width: "75%" }}
                            >
                              <div style={{ width: "100%" }}>
                                <Select
                                  labelId="super-category-label"
                                  className="textfield"
                                  label="Order Status"
                                  id="demo-simple-select"
                                  inputProps={{
                                    style: { textAlign: "center" },
                                  }}
                                  value={orderText}
                                  onChange={(e) => selectcurrentOrderStatus(e)}
                                >
                                  {OrderStatus &&
                                    OrderStatus.map((indCat, index) => (
                                      <MenuItem key={index} value={indCat.name}>
                                        {indCat.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex items-center gap-2 justify-end"
                    style={{ marginBottom: "60px" }}
                  >
                    <button
                      className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                      style={{
                        background: "#4D44B5",
                        outline: "none",
                        border: "none",
                      }}
                      onClick={() => {
                        setUpdateBoxShow(false);
                        setSearch("");
                      }}
                    >
                      <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                      Search New OrderID
                    </button>
                    <button
                      className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                      style={{
                        background: "#4D44B5",
                        outline: "none",
                        border: "none",
                      }}
                      onClick={(e) => handlesubmit(e)}
                    >
                      <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                      Submit
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="p-2 space-y-6">
                  <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                    <div
                      className="flex items-center justify-between py-2 px-5"
                      style={{
                        backgroundColor: "#4D44B5",
                        color: "#fff",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      <h4
                        className="text-lg font-medium text-default-950 capitalize"
                        style={{
                          color: "#fff",
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                      >
                        Payment Status Update
                      </h4>
                    </div>
                    <div className="p-5 border-t border-dashed border-default-200">
                      <div className="grid md:grid-cols-2 gap-4">
                        <div className="space-y-1">
                          <div className="form-flex">
                            <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: "600", color: "#000066" }}
                            >
                              Order Id :
                            </label>
                            <div
                              className="textfieldcontainer"
                              style={{ width: "80%" }}
                            >
                              <TextField
                                label="Enter Order Id"
                                variant="outlined"
                                className="textfield"
                                type="text"
                                id="text"
                                required
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex items-center gap-2 justify-end"
                    style={{ marginBottom: "60px" }}
                  >
                    <button
                      className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                      style={{
                        background: "#4D44B5",
                        outline: "none",
                        border: "none",
                      }}
                      onClick={(e) => getOrderDetails(e)}
                    >
                      <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                      Search Order
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default UpdatePayment;
