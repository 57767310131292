import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControlLabel, InputAdornment, TextField } from "@mui/material";
import {
  subCategoryImages,
  subCategoryPost,
  subCategoryUpdate,
} from "../../../redux/SubCategory/SubCategorySlice";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl } from "../../../config/BaseUrl";
import Switch from "@mui/material/Switch";
const label = { inputProps: { "aria-label": "Size switch demo" } };

const EditSubCategory = () => {
  const params = useParams();
  const { imageLoading, desktopImage } = useSelector(
    (store) => store.subCategory
  );
  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { categoryList } = useSelector((store) => store.category);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState();
  const [slugUrl, setSlugUrl] = useState("");
  const [superCategory, setSuperCategory] = useState("");
  const [superCategoryId, setSuperCategoryId] = useState("");
  const [catbySupercat, setCatbySupercat] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [nameError, setNameError] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [status, setStatus] = useState(false);
  const [priority, setpriority] = useState("");

  useEffect(() => {
    const getSubCategory = async () => {
      if (params.slugurl) {
        const url = `${Baseurl}/api/v1/subcategory/getSingleSubCategory/${params.slugurl}`;
        const resp = await axios.get(url);
        if (resp.data.success) {
          setName(resp.data.subCategory.name);
          setSubCategoryId(resp.data.subCategory._id);
          setSuperCategory(resp.data.subCategory.superCategory);
          setSuperCategoryId(resp.data.subCategory.superCategoryId);
          setCategory(resp.data.subCategory.category);
          setCategoryId(resp.data.subCategory.categoryId);
          setMetaTitle(resp.data.subCategory.metaTitle);
          setMetaDesc(resp.data.subCategory.metaDesc);
          setMetaKeyword(resp.data.subCategory.metaKeyword);
          setpriority(resp.data.subCategory.priority);
          setStatus(resp.data.subCategory.status);
        }
      }
    };
    getSubCategory();
  }, [params.id]);

  const handleSuperCatgory = (e) => {
    setSuperCategoryId(e.target.value);
    const getSuperCategoryName = superCatTotal.find(
      (indCat) => indCat._id === e.target.value
    );
    if (getSuperCategoryName) {
      setSuperCategory(getSuperCategoryName);
    }
  };

  useEffect(() => {
    const catbysupercat = categoryList.filter(
      (cat) => cat.superCategoryId === superCategoryId
    );
    setCatbySupercat(catbysupercat);
  }, [superCategoryId, categoryList]);

  const handleCatgory = (e) => {
    setCategoryId(e.target.value);
    const getCategoryName = categoryList.find(
      (indCat) => indCat._id === e.target.value
    );
    if (getCategoryName) {
      setCategory(getCategoryName);
    }
  };
  const categoryImageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(subCategoryImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleChangestatus = (event) => {
    const newChecked = event.target.checked;
    setStatus(newChecked);
  };

  const handleData = async () => {
    const formData = {
      _id: subCategoryId,
      name: name,
      slugUrl: name,
      superCategoryId: superCategoryId,
      superCategory: superCategory.name,
      superCategoryUrl: superCategory.slugUrl,
      categoryId: categoryId,
      category: category.name,
      categoryUrl: category.slugUrl,
      mobileImage: desktopImage,
      desktopImage: desktopImage,
      metaTitle: metaTitle,
      metaKeyword: metaKeyword,
      metaDesc: metaDesc,
      priority: priority,
      status: status,
    };
    console.log(formData, "formData");
    const response = await dispatch(subCategoryUpdate(formData));
    console.log(response.payload, ".payload");

    if (response.payload.success) {
      alert(`SubCategory With ${name} updated`);
      setName("");
      setSlugUrl("");
      setCategory("");
      setCategoryId("");
      setMetaTitle("");
      setMetaKeyword("");
      setMetaDesc("");
      setpriority("");
      setStatus(false);
      navigate("/admin/listsubcategory");
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
              >
                Edit Sub Category
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Super Category:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <div style={{ width: "100%" }}>
                        <Select
                          labelId="super-category-label"
                          className="textfield"
                          label="Super Category"
                          id="demo-simple-select"
                          value={superCategoryId}
                          inputProps={{ style: { textAlign: "center" } }}
                          onChange={(e) => handleSuperCatgory(e)}
                        >
                          {superCatTotal &&
                            superCatTotal.map((indCat, index) => (
                              <MenuItem key={index} value={indCat._id}>
                                {indCat.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Category:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <div style={{ width: "100%" }}>
                        <Select
                          labelId="Category"
                          className="textfield"
                          label="Category"
                          id="demo-simple-select"
                          value={categoryId}
                          inputProps={{ style: { textAlign: "center" } }}
                          onChange={(e) => handleCatgory(e)}
                        >
                          {catbySupercat &&
                            catbySupercat.map((indCat, index) => (
                              <MenuItem key={index} value={indCat._id}>
                                {indCat.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Name :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Name"
                        variant="outlined"
                        className={
                          nameError !== ""
                            ? "textfield error_class"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Title :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Meta Title"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={metaTitle}
                        onChange={(e) => setMetaTitle(e.target.value)}
                        // className={classes.textfield}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Keyword:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Meta keyword"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={metaKeyword}
                        onChange={(e) => setMetaKeyword(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Desc :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Meta Desc"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={metaDesc}
                        onChange={(e) => setMetaDesc(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Priority :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Priority"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={priority}
                        onChange={(e) => setpriority(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="vehicleWeight"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Upload Image * :
                    </label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="file"
                        className={
                          "block rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 "
                        }
                        onChange={(e) => categoryImageChange(e)}
                      />
                    </div>
                  </div>
                  {imageLoading ? (
                    <></>
                  ) : (
                    <div>
                      <img
                        src={desktopImage}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    </div>
                  )}
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="vehicleWeight"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: "600", color: "#000066" }}
                  >
                    Status *
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          id="statusSwitch"
                          checked={status}
                          onChange={handleChangestatus}
                        />
                      }
                      label={status ? "Yes" : "No"}
                    />
                    {/* <Switch
                      {...label}
                      checked={status}
                      onChange={handleChangestatus}
                      inputProps={{ "aria-label": "controlled" }}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{ background: "#4D44B5", outline: "none", border: "none" }}
              onClick={() => handleData()}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSubCategory;
