import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  stocktotal: localStorage.getItem("stocktotal")
    ? JSON.parse(localStorage.getItem("stocktotal"))
    : [],
  isLoading: true,
  stockLoading: true,
  delstockLoading: true,
};

export const getStock = createAsyncThunk("Stock/getStock", async (thunkAPI) => {
  try {
    const url = `${Baseurl}/api/v1/stock/all`;
    const resp = await axios(url);
    return resp.data.stocks;
  } catch (error) {
    return thunkAPI.rejectWithValue("404 Not Found");
  }
});

export const stockPost = createAsyncThunk(
  "stock/stockPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/stock/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("stock Not create");
    }
  }
);

export const stockUpdate = createAsyncThunk(
  "stock/stockUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/stock/${formData.stockid}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("stock Not create");
    }
  }
);

export const stockDelete = createAsyncThunk(
  "stock/stockDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/stock/${id}`;
      const resp = await axios.delete(url, id, config);
      console.log(resp.data);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("stock Not create");
    }
  }
);

const StockSlice = createSlice({
  name: "Stocks",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getStock.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStock.fulfilled, (state, action) => {
        state.stocktotal = action.payload;
        state.isLoading = false;
        state.stockLoading = false;
        localStorage.setItem("stocktotal", JSON.stringify(state.stocktotal));
      })
      .addCase(getStock.rejected, (state) => {
        state.isLoading = true;
      })

      .addCase(stockPost.pending, (state) => {
        state.stockLoading = true;
      })
      .addCase(stockPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.stocktotal = [...state.stocktotal, action.payload.stock];
          localStorage.setItem("stocktotal", JSON.stringify(state.stocktotal));
        }
        state.stockLoading = false;
      })
      .addCase(stockPost.rejected, (state) => {
        state.stockLoading = true;
      })

      .addCase(stockUpdate.pending, (state) => {
        state.stockLoading = true;
      })
      .addCase(stockUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.stocktotal = state.stocktotal.filter(
            (stock) => stock._id !== action.payload.stock._id
          );
          state.stocktotal = [...state.stocktotal, action.payload.stock];
          localStorage.setItem("stocktotal", JSON.stringify(state.stocktotal));
        }
        state.stockLoading = false;
      })
      .addCase(stockUpdate.rejected, (state) => {
        state.stockLoading = true;
      })

      .addCase(stockDelete.pending, (state) => {
        state.delstockLoading = true;
      })
      .addCase(stockDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.stocktotal = state.stocktotal.filter(
            (stock) => stock._id !== action.payload.id
          );
          localStorage.setItem("stocktotal", JSON.stringify(state.stocktotal));
        }
        state.delstockLoading = false;
      })
      .addCase(stockDelete.rejected, (state) => {
        state.delstockLoading = true;
      });
  },
});
export default StockSlice.reducer;
