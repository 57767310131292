import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  storetotal: localStorage.getItem("storetotal")
    ? JSON.parse(localStorage.getItem("storetotal")).sort()
    : [],
  storeImages: [],
  storeLogo: "",
  isLoading: true,
  storeLoading: true,
  storeImageLoading: true,
  storeLogoLoading: true,
  isStoreAvailable: false,
  delbrandLoading: true,
};

export const getStore = createAsyncThunk("store/getStore", async (thunkAPI) => {
  try {
    const url = `${Baseurl}/api/v1/store/all`;
    const resp = await axios(url);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue("404 Not Found");
  }
});

export const storePost = createAsyncThunk(
  "store/storePost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/store/new`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data, "resp.data");

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Store Not create");
    }
  }
);

export const validatestoreSlugUrl = createAsyncThunk(
  "store/validatestoreSlugUrl",
  async (slugUrl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    };
    try {
      const url = `${Baseurl}/api/v1/store/slugUrl/${slugUrl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const getStoreImages = createAsyncThunk(
  "store/getStoreImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/store/storeimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("storeImages Not create");
    }
  }
);
export const getStoreLogo = createAsyncThunk(
  "store/getStoreLogo",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/store/storelogo`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("storelogo Not create");
    }
  }
);

export const storeUpdate = createAsyncThunk(
  "store/storeUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/store/update/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("brand Not create");
    }
  }
);

export const storeDelete = createAsyncThunk(
  "store/storeDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/store/delete/${id}`;
      const resp = await axios.delete(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("brand Not create");
    }
  }
);

const StoreSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setStoreImage(state, action) {
      state.storeImageLoading = action.payload.status;
      state.storeLogoLoading = action.payload.logostatus;
      state.storeImages = action.payload.image;
      state.storeLogo = action.payload.logo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(storePost.pending, (state) => {
        state.isStoreAvailable = false;
        state.storeLoading = true;
      })
      .addCase(storePost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.storetotal = [...state.storetotal, action.payload.store];
        }
        state.isStoreAvailable = true;
        state.storeLoading = false;
      })
      .addCase(storePost.rejected, (state) => {
        state.isStoreAvailable = false;
        state.storeLoading = true;
      })

      .addCase(getStore.pending, (state) => {
        state.isStoreAvailable = false;
        state.storeLoading = true;
      })
      .addCase(getStore.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.storetotal = action.payload.store;
        }
        localStorage.setItem("storetotal", JSON.stringify(state.storetotal));
        state.isStoreAvailable = true;
        state.storeLoading = false;
      })
      .addCase(getStore.rejected, (state) => {
        state.isStoreAvailable = false;
        state.storeLoading = true;
      })

      .addCase(storeUpdate.pending, (state) => {
        state.isStoreAvailable = false;
        state.storeLoading = true;
      })
      .addCase(storeUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.storetotal = state.storetotal.filter((store) => {
            return store._id !== action.payload.store._id;
          });
          localStorage.setItem("storetotal", JSON.stringify(state.storetotal));
          state.storetotal = [action.payload.store, ...state.storetotal];
        }
        state.isStoreAvailable = true;
        state.storeLoading = false;
      })
      .addCase(storeUpdate.rejected, (state) => {
        state.isStoreAvailable = false;
        state.storeLoading = true;
      })

      .addCase(storeDelete.pending, (state) => {
        state.isStoreAvailable = false;
        state.storeLoading = true;
      })
      .addCase(storeDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.storetotal = state.storetotal.filter((store) => {
            return store._id !== action.payload.store._id;
          });
        }
        localStorage.setItem("storetotal", JSON.stringify(state.storetotal));
        state.isStoreAvailable = true;
        state.storeLoading = false;
      })
      .addCase(storeDelete.rejected, (state) => {
        state.isStoreAvailable = false;
        state.storeLoading = true;
      })

      .addCase(getStoreImages.pending, (state) => {
        state.storeImageLoading = true;
      })
      .addCase(getStoreImages.fulfilled, (state, action) => {
        state.storeImageLoading = false;
        state.storeImages = action.payload.storeImages;
      })
      .addCase(getStoreImages.rejected, (state) => {
        state.storeImageLoading = true;
      })

      .addCase(getStoreLogo.pending, (state) => {
        state.storeLogoLoading = true;
      })
      .addCase(getStoreLogo.fulfilled, (state, action) => {
        state.storeLogoLoading = false;
        state.storeLogo = action.payload.logo;
      })
      .addCase(getStoreLogo.rejected, (state) => {
        state.storeLogoLoading = true;
      });
  },
});
export const storeActions = StoreSlice.actions;
export default StoreSlice.reducer;
