import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import BeatLoader from "react-spinners/BeatLoader";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { Link } from "react-router-dom";
const UserData = () => {
  const { clienttotal, isLoading } = useSelector((store) => store.clientAll);
  const [temploading, setTempLoading] = useState(true);
  const [walletHistory, setWalletHistory] = useState([]);
  const [search, setSearch] = useState("");
  const [filtered, setfiltered] = useState([]);

  //   useEffect(() => {
  //     if (clienttotal.length > 0) {
  //       setWalletHistory([...clienttotal]);
  //     } else {
  //       setWalletHistory([]);
  //     }
  //   }, [clienttotal]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/client/limit-clients`;
      const fetchwalletHistory = await axios.get(url);
      if (fetchwalletHistory.data.success === true) {
        const wallettotal = fetchwalletHistory.data.clients;
        setWalletHistory(wallettotal);
        setTempLoading(false);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    let walletHistoryFilter = walletHistory;

    if (search !== "") {
      walletHistoryFilter = walletHistoryFilter.filter((bushisto) => {
        return bushisto.Mobile.toLowerCase().match(search.toLowerCase());
      });
    }
    let reverdata = walletHistoryFilter.slice().reverse();
    setfiltered(reverdata);
  }, [search, walletHistory]);
  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-3 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              User Details
            </h4>
            <div className="space-y-1">
              <div className="form-flex">
                <div className="textfieldcontainer" style={{ width: "100%" }}>
                  <TextField
                    label="Search by number"
                    variant="outlined"
                    className="textfield"
                    type="text"
                    id="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ textTransform: "uppercase" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {temploading ? (
          <>
            <div className="d-flex justify-content-center loadingMain">
              <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                User Data is being fetched
              </h4>
              <BeatLoader
                color={"#36d7b7"}
                loading={isLoading}
                size={10}
                className="mt-2 mx-2"
              />
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-1">
              <div
                className="shadow rounded-lg bg-white dark:bg-default-50"
                style={{ marginBottom: "80px" }}
              >
                <div className="border-t border-dashed border-default-200">
                  <div className="relative overflow-x-auto">
                    <table className="min-w-full overflow-x-hidden  table-striped">
                      <thead className="border-b border-dashed border-default-200">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                          >
                            No
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                          >
                            Mobile
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                          >
                            Cart
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            Wallet
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            Orders
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filtered &&
                          filtered.map((indOrder, index) => (
                            <tr
                              style={{
                                background: index % 2 === 0 ? "#eee" : "#fff",
                              }}
                            >
                              <td
                                scope="col"
                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                              >
                                {index + 1}
                              </td>
                              <td
                                scope="col"
                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                                style={{ color: "green", cursor: "pointer" }}
                              >
                                {indOrder.Name}
                              </td>
                              <td
                                scope="col"
                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                              >
                                {indOrder.Mobile}
                              </td>
                              <td
                                scope="col"
                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                              >
                                {indOrder.Email}
                              </td>
                              <td
                                scope="col"
                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                              >
                                <Link
                                  to={`/admin/user-cart-history/${indOrder._id}`}
                                >
                                  <button
                                    style={{
                                      backgroundColor: "#1976d2",
                                      color: "#fff",
                                      padding: "10px 20px",
                                      borderRadius: "5px",
                                    }}
                                    // onClick={() => showModal(indOrder)}
                                  >
                                    Cart
                                  </button>
                                </Link>
                              </td>
                              <td
                                scope="col"
                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                              >
                                <Link
                                  to={`/admin/user-wallet-history/${indOrder._id}`}
                                >
                                  <button
                                    style={{
                                      backgroundColor: "#1976d2",
                                      color: "#fff",
                                      padding: "10px 20px",
                                      borderRadius: "5px",
                                    }}
                                    // onClick={() => showModal(indOrder)}
                                  >
                                    Wallet
                                  </button>
                                </Link>
                              </td>
                              <td
                                scope="col"
                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                              >
                                <Link
                                  to={`/admin/user-order-history/${indOrder._id}`}
                                >
                                  <button
                                    style={{
                                      backgroundColor: "#1976d2",
                                      color: "#fff",
                                      padding: "10px 20px",
                                      borderRadius: "5px",
                                    }}
                                    // onClick={() => showModal(indOrder)}
                                  >
                                    Orders
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserData;
