import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import {
  getStoreImages,
  getStoreLogo,
  storeActions,
  storePost,
  storeUpdate,
  validatestoreSlugUrl,
} from "../../redux/store/StoreSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const EditStore = () => {
  const { storeImages, storeImageLoading, storeLogoLoading, storeLogo } =
    useSelector((store) => store.store);

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [storeId, setStoreId] = useState("");
  const [name, setName] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [storeType, setStoreType] = useState("");
  const [description, setDescription] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [locality, setLocality] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");

  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [errorMobile, setErrorMobile] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorsAddress, setErrorsAddress] = useState({
    streetAddress: "",
    locality: "",
    city: "",
    state: "",
    pincode: "",
  });

  useEffect(() => {
    const getstore = async () => {
      if (params.storeUrl) {
        const url = `${Baseurl}/api/v1/store/getstoreBySlugUrl/${params.storeUrl}`;
        const resp = await axios.get(url);
        console.log(resp.data.store, "1");
        if (resp.data.success) {
          setStoreId(resp.data.store._id);
          setName(resp.data.store.storeName);
          setSlugUrl(resp.data.store.storeUrl);
          setStoreType(resp.data.store.storeType);
          setDescription(resp.data.store.description);
          setOwnerName(resp.data.store.ownerName);
          setMobile(resp.data.store.mobile);
          setEmail(resp.data.store.email);
          setStreetAddress(resp.data.store.address.streetAddress);
          setLocality(resp.data.store.address.locality);
          setLongitude(resp.data.store.address.longitude);
          setLatitude(resp.data.store.address.latitude);
          setCity(resp.data.store.address.city);
          setState(resp.data.store.address.state);
          setPincode(resp.data.store.address.pinCode);
          const formData = {
            status: false,
            logostatus: false,
            image: resp.data.store.storeImages,
            logo: resp.data.store.logo,
          };
          await dispatch(storeActions.setStoreImage(formData));
        }
      }
    };
    getstore();
  }, [params.storeUrl]);

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setCheckSlugUrl("");
    const storeSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(storeSlug);

    if (storeSlug !== "") {
      let responce = await dispatch(validatestoreSlugUrl(storeSlug));
      if (responce.payload.success) {
        setError("Store alredy exist");
        setErrorcolor("red");
      } else if (storeSlug.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (storeSlug.length >= 3) {
        setErrorcolor(" ");
        setError("");
        setCheckSlugUrl(storeSlug);
      }
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    setMobile(numericValue);
    if (numericValue.length === 10) {
      setErrorMobile("");
    } else {
      setErrorMobile("Please enter a valid 10-digit mobile number.");
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(value)) {
      setErrorEmail("");
    } else {
      setErrorEmail("Please enter a valid email address.");
    }
  };

  const handleStreetAddressChange = (e) => {
    const value = e.target.value;
    setStreetAddress(value);
    if (value.trim() === "") {
      setErrorsAddress((prevErrors) => ({
        ...prevErrors,
        streetAddress: "Please enter an address.",
      }));
    } else {
      setErrorsAddress((prevErrors) => ({ ...prevErrors, streetAddress: "" }));
    }
  };

  const handleLocalityChange = (e) => {
    const value = e.target.value;
    setLocality(value);
    if (value.trim() === "") {
      setErrorsAddress((prevErrors) => ({
        ...prevErrors,
        locality: "Please enter locality.",
      }));
    } else {
      setErrorsAddress((prevErrors) => ({ ...prevErrors, locality: "" }));
    }
  };

  const handleLongitudeChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9.-]/g, "");
    setLongitude(numericValue);

    if (numericValue === "" || !/^-?\d+(\.\d+)?$/.test(numericValue)) {
      setErrorsAddress((prevErrors) => ({
        ...prevErrors,
        longitude: "Please enter a valid longitude.",
      }));
    } else {
      setErrorsAddress((prevErrors) => ({ ...prevErrors, longitude: "" }));
    }
  };

  const handleLatitudeChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9.-]/g, "");
    setLatitude(numericValue);
    if (numericValue === "" || !/^-?\d+(\.\d+)?$/.test(numericValue)) {
      setErrorsAddress((prevErrors) => ({
        ...prevErrors,
        latitude: "Please enter a valid latitude.",
      }));
    } else {
      setErrorsAddress((prevErrors) => ({ ...prevErrors, latitude: "" }));
    }
  };

  const handleCityChange = (e) => {
    const value = e.target.value;
    setCity(value);
    setErrorsAddress((prevErrors) => ({
      ...prevErrors,
      city: value.trim() ? "" : "Please enter a city.",
    }));
  };

  const handleStateChange = (e) => {
    const value = e.target.value;
    setState(value);
    setErrorsAddress((prevErrors) => ({
      ...prevErrors,
      state: value.trim() ? "" : "Please enter a state.",
    }));
  };

  const handlePincodeChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    setPincode(numericValue);
    if (numericValue.length === 6) {
      setErrorsAddress((prevErrors) => ({ ...prevErrors, pincode: "" }));
    } else {
      setErrorsAddress((prevErrors) => ({
        ...prevErrors,
        pincode: "Pincode must be exactly 6 digits.",
      }));
    }
  };

  const brandImageChange = (e) => {
    const files = Array.from(e.target.files);
    const imageArray = [];
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          imageArray.push(reader.result);
          if (imageArray.length === files.length) {
            dispatch(getStoreImages({ storeImages: imageArray }));
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const logoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(getStoreLogo({ logo: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handlesubmit = async (e) => {
    if (checkslugUrl !== "") {
      const formData = {
        storeName: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        storeUrl: slugUrl,
        mobile: mobile,
        email: email,
        storeType: storeType,
        description: description,
        ownerName: ownerName,
        logo: storeLogo,
        storeImages: storeImages,
        address: {
          longitude: longitude,
          latitude: latitude,
          streetAddress: streetAddress,
          locality: locality,
          city: city,
          state: state,
          pinCode: pincode,
          country: "India",
        },
      };
      const data = await dispatch(storeUpdate(formData));
      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Store Updated");
          setName("");
          setSlugUrl("");
          setStoreType("");
          setDescription("");
          setOwnerName("");
          setMobile("");
          setEmail("");
          setStreetAddress("");
          setLocality("");
          setLongitude("");
          setLatitude("");
          setCity("");
          setState("");
          setPincode("");

          const formData = {
            status: true,
            logostatus: true,
            image: [],
            logo: "",
          };
          await dispatch(storeActions.setStoreImage(formData));
          navigate("/admin/liststore");
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    } else {
      alert("Please enter correct values");
    }
  };

  const handleReset = async () => {
    setName("");
    setSlugUrl("");
    setStoreType("");
    setDescription("");
    setOwnerName("");
    setMobile("");
    setEmail("");
    setStreetAddress("");
    setLocality("");
    setLongitude("");
    setLatitude("");
    setCity("");
    setState("");
    setPincode("");
    const formData = {
      status: true,
      logostatus: true,
      image: [],
      logo: "",
    };
    await dispatch(storeActions.setStoreImage(formData));
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
              >
                Add Store Details
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Name :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Name"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={name}
                        required
                        onChange={(e) => verifyslugurl(e)}
                        error={!!error}
                        helperText={error}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Store Type :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enetr Store Type"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        required
                        value={storeType}
                        onChange={(e) => setStoreType(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Description :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Description"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        required
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Owner Name :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <TextField
                        label="Enter Owner Name"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        required
                        value={ownerName}
                        onChange={(e) => setOwnerName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Mobile No :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Mobile Number"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        required
                        inputProps={{
                          minLength: 10,
                          maxLength: 10,
                        }}
                        value={mobile}
                        onChange={(e) => handleMobileChange(e)}
                        error={!!errorMobile}
                        helperText={errorMobile}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Email :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Email"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        required
                        value={email}
                        onChange={(e) => handleEmailChange(e)}
                        error={!!errorEmail}
                        helperText={errorEmail}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Street Address :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <TextField
                        label="Enter Address"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        required
                        value={streetAddress}
                        onChange={(e) => handleStreetAddressChange(e)}
                        error={!!errorsAddress.streetAddress}
                        helperText={errorsAddress.streetAddress}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Locality :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <TextField
                        label="Enter Address"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        required
                        value={locality}
                        onChange={(e) => handleLocalityChange(e)}
                        error={!!errorsAddress.locality}
                        helperText={errorsAddress.locality}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Longitude :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <TextField
                        label="Enter Address"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        required
                        value={longitude}
                        onChange={(e) => handleLongitudeChange(e)}
                        error={!!errorsAddress.longitude}
                        helperText={errorsAddress.longitude}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Latitude :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <TextField
                        label="Enter Address"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        required
                        value={latitude}
                        onChange={(e) => handleLatitudeChange(e)}
                        error={!!errorsAddress.latitude}
                        helperText={errorsAddress.latitude}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      City :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter City"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        required
                        value={city}
                        onChange={(e) => handleCityChange(e)}
                        error={!!errorsAddress.city}
                        helperText={errorsAddress.city}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      State :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter State"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        required
                        value={state}
                        onChange={(e) => handleStateChange(e)}
                        error={!!errorsAddress.state}
                        helperText={errorsAddress.state}
                      />
                    </div>
                  </div>
                </div>

                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Pincode :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Pincode"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        required
                        value={pincode}
                        onChange={(e) => handlePincodeChange(e)}
                        inputProps={{
                          minLength: 6,
                          maxLength: 6,
                        }}
                        error={!!errorsAddress.pincode}
                        helperText={errorsAddress.pincode}
                      />
                    </div>
                  </div>
                </div>

                <div className="space-y-1"></div>

                <div
                  className="space-y-1"
                  style={{
                    paddingTop: "50px",
                    marginBottom: "50px",
                  }}
                >
                  <label
                    htmlFor="vehicleWeight"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: "600", color: "#000066" }}
                  >
                    Upload Store Images *
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="file"
                      className={
                        "block rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 "
                      }
                      multiple
                      onChange={(e) => brandImageChange(e)}
                    />
                  </div>
                  <div>
                    {storeImageLoading ? (
                      <></>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          flexWrap: "wrap",
                        }}
                      >
                        {storeImages && storeImages.length > 0 ? (
                          storeImages.map((item, index) => (
                            <div key={index}>
                              <img
                                src={item.storeimage}
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  objectFit: "cover",
                                }}
                                alt="Store Upload"
                              />
                            </div>
                          ))
                        ) : (
                          <p>No images to display</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="space-y-1"
                  style={{
                    paddingTop: "50px",
                    marginBottom: "50px",
                  }}
                >
                  <label
                    htmlFor="vehicleWeight"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: "600", color: "#000066" }}
                  >
                    Upload Store Logo *
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="file"
                      className={
                        "block rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 "
                      }
                      onChange={(e) => logoChange(e)}
                    />
                  </div>
                  <div>
                    {storeLogoLoading ? (
                      <></>
                    ) : (
                      <img
                        src={storeLogo}
                        style={{ height: "100px", width: "100px" }}
                        alt="img"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            {/* <button
              style={{ background: "#ef4444", outline: "none", border: "none" }}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              onClick={() => handleReset()}
            >
              <i className="ph-bold ph-x text-lg" /> Reset
            </button> */}
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{ background: "#4D44B5", outline: "none", border: "none" }}
              onClick={(e) => handlesubmit(e)}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditStore;
