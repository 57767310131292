import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import {
  superCategoryPost,
  validateSlugUrl,
} from "../../../redux/SuperCategory/SuperCategorySlice";

const AddSuperCategory = () => {
  const dispatch = useDispatch();
  const { desktopimage, imageLoading } = useSelector((store) => store.category);
  const [name, setName] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkSlugUrl, setCheckSlugUrl] = useState("");
  const [nameError, setNameError] = useState("");
  const [errorcolor, setErrorcolor] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDesc, setMetaDesc] = useState("");

  const [formerror_msg, setformerror_msg] = useState("");
  const [formerror_status, setformerror_status] = useState(false);

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setErrorcolor("green");
    setCheckSlugUrl("");
    setNameError("super Category available");
    const nameSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(nameSlug);

    if (nameSlug !== "") {
      let responce = await dispatch(validateSlugUrl(nameSlug));
      if (responce.payload.success) {
        setNameError("super category alredy exist");
        setErrorcolor("red");
      } else if (nameSlug.length <= 2) {
        setErrorcolor("red");
        setNameError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (nameSlug.length >= 3) {
        setErrorcolor("green");
        setNameError("");
        setCheckSlugUrl(nameSlug);
      }
    }
  };

  const handlesubmit = async (e) => {
    if (checkSlugUrl !== "") {
      const formData = {
        name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        slugUrl: checkSlugUrl,
        metaTitle: metaTitle,
        metaKeyword: metaKeyword,
        metaDesc: metaDesc,
      };
      const resp = await dispatch(superCategoryPost(formData));

      if (!resp) {
        <div></div>;
      } else {
        if (resp.payload.success) {
          alert("Supercategory Added");
          setName("");
          setSlugUrl("");
          setMetaTitle("");
          setMetaKeyword("");
          setMetaDesc("");
        } else {
          alert(resp.message);
        }
      }
    } else {
      alert("Don't forget to fill in this field!");
    }
  };

  const handleReset = () => {
    setName("");
    setMetaTitle("");
    setMetaKeyword("");
    setMetaDesc("");
  };
  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
              >
                Add Super Category Details
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Name :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Name"
                        variant="outlined"
                        className={
                          nameError !== ""
                            ? "textfield error_class"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        value={name}
                        onChange={(e) => verifyslugurl(e)}
                      />
                    </div>
                  </div>
                  <div style={{ color: "red" }}>{nameError}</div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Title :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Meta Title"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={metaTitle}
                        onChange={(e) => setMetaTitle(e.target.value)}
                        // className={classes.textfield}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Keyword :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <TextField
                        label="Enter Meta Keyword"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={metaKeyword}
                        onChange={(e) => setMetaKeyword(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Desc :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Meta Desc"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={metaDesc}
                        onChange={(e) => setMetaDesc(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* <div
                  className="space-y-1"
                  style={{
                    paddingTop: "50px",
                    marginBottom: "50px",
                  }}
                >
                  <label
                    htmlFor="vehicleWeight"
                    className="text-sm font-medium text-default-900"
                    style={{ fontWeight: "600", color: "#000066" }}
                  >
                    Upload SuperCategory Image *
                  </label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <input
                      type="file"
                      className={
                        "block rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 "
                      }
                      onChange={(e) => superCategoryImageChange(e)}
                    />
                  </div>
                  <div>
                    {
                      imageLoading ?
                        <></> :
                        <img
                          src={desktopimage}
                          style={{ height: "100px", width: "100px" }}
                          alt="img"
                        />
                    }
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="card-footer ">
                  {checkSlugUrl !== "" ? (
                    <button className="btn btn-primary" type="submit">
                      Add Super Category
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Super Category
                    </button>
                  )}
                </div> */}
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            <button
              style={{ background: "#ef4444", outline: "none", border: "none" }}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              onClick={() => handleReset()}
            >
              <i className="ph-bold ph-x text-lg" /> Reset
            </button>
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{ background: "#4D44B5", outline: "none", border: "none" }}
              onClick={() => handlesubmit()}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSuperCategory;
