import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import BeatLoader from "react-spinners/BeatLoader";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { useParams } from "react-router-dom";
const ClientCartHistory = () => {
  const [temploading, setTempLoading] = useState(true);
  const [walletHistory, setWalletHistory] = useState([]);
  const [search, setSearch] = useState("");
  const [filtered, setfiltered] = useState([]);
  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/cart/cartupdatebyid/${params.id}`;
      const fetchwalletHistory = await axios.get(url);
      if (fetchwalletHistory.data.success === true) {
        const wallettotal = fetchwalletHistory.data.cart;
        const getcart = wallettotal.OrderProducts;
        setWalletHistory(getcart);
        setTempLoading(false);
      } else {
        setTempLoading(false);
        setWalletHistory([]);
      }
    };
    fetchData();
  }, [params.id]);
  useEffect(() => {
    let walletHistoryFilter = walletHistory;

    if (search !== "") {
      walletHistoryFilter = walletHistoryFilter.filter((bushisto) => {
        return bushisto.Mobile.toLowerCase().match(search.toLowerCase());
      });
    }

    let reverdata = walletHistoryFilter.slice().reverse();
    setfiltered(reverdata);
  }, [search, walletHistory]);
  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-3 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              Cart History
            </h4>
            <div className="space-y-1">
              <div className="form-flex">
                <div className="textfieldcontainer" style={{ width: "100%" }}>
                  <TextField
                    label="Search by number"
                    variant="outlined"
                    className="textfield"
                    type="text"
                    id="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ textTransform: "uppercase" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {temploading ? (
          <>
            <div className="d-flex justify-content-center loadingMain">
              <h4 style={{ fontWeight: "bold", color: "#36d7b7" }}>
                Cart is being fetched
              </h4>
              <BeatLoader
                color={"#36d7b7"}
                loading={temploading}
                size={10}
                className="mt-2 mx-2"
              />
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-1">
              <div
                className="shadow rounded-lg bg-white dark:bg-default-50"
                style={{ marginBottom: "80px" }}
              >
                <div className="border-t border-dashed border-default-200">
                  <div className="relative overflow-x-auto">
                    <table className="min-w-full overflow-x-hidden  table-striped">
                      <thead className="border-b border-dashed border-default-200">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                          >
                            No
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                          >
                            Product Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                          >
                            PackSize
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                          >
                            Image
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                          >
                            Price
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            Qty
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            TotalAmount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filtered &&
                          filtered.map((indOrder, index) => (
                            <tr
                              style={{
                                background: index % 2 === 0 ? "#eee" : "#fff",
                              }}
                            >
                              <td
                                scope="col"
                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                              >
                                {index + 1}
                              </td>
                              <td
                                scope="col"
                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                                style={{ color: "green", cursor: "pointer" }}
                              >
                                {indOrder.ItemName}
                              </td>
                              <td
                                scope="col"
                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                              >
                                {indOrder.PackSize}
                              </td>
                              <td
                                scope="col"
                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                              >
                                <img
                                  src={indOrder.ImgUrl}
                                  style={{
                                    maxHeight: "30px",
                                  }}
                                  alt="img"
                                />
                              </td>
                              <td
                                scope="col"
                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                              >
                                {indOrder.Price}
                              </td>
                              <td
                                scope="col"
                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                              >
                                {indOrder.Qty}
                              </td>
                              <td
                                scope="col"
                                className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                              >
                                {indOrder.TotalAmount}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ClientCartHistory;
