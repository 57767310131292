import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "../redux/Authentication/AuthSlice";
import CategoryReducer from "../redux/Category/CategorySlice";
import SubCategoryReducer from "./SubCategory/SubCategorySlice";
import groceryReducer from "./Product/ProductSlice";
import OrderReducer from "./Order/OrderSlice";
import superCategoryReducer from "./SuperCategory/SuperCategorySlice";
import BrandReducer from "./Brand/BrandSlice";
import subscriptionsReducer from "./subscription/subscriptionSlice";
import CouponsReducer from "./coupon/couponSlice";
import SlidersReducer from "./slider/sliderSlice";
import SubcatbannerReducer from "./subCatBanner/subCatBannerSlice";
import clientReducer from "./client/clientSlice";
import storeReducer from "./store/StoreSlice";
import BestOffersReducer from "./AllBanner&Slider/BestOffersSlice";
import FeaturedInWeekReducer from "./AllBanner&Slider/FeaturedInWeekSlice";
import InfocusTodayReducer from "./AllBanner&Slider/InFocusTodaySlice";
import TodayFeaturedReducer from "./AllBanner&Slider/TodayFeaturedSlice";
import TopBannerReducer from "./AllBanner&Slider/TopBannerSlice";
import EmployeesReducer from "./emplyees/employeesSlice";
import OffersbannerReducer from "./offersBanner/offersBannerSlice";
import PinamountsReducer from "./pinAmount/pinAmountSlice";
import StocksReducer from "./stock/stockSlice";
import TagsReducer from "./tags/TagSlice";
import VegPageReducer from "./vegpage/VegPageSlice";

export const store = configureStore({
  reducer: {
    clientAll: clientReducer,
    auth: AuthReducer,
    superCategory: superCategoryReducer,
    category: CategoryReducer,
    brand: BrandReducer,
    subCategory: SubCategoryReducer,
    grocery: groceryReducer,
    order: OrderReducer,
    store: storeReducer,
    subscriptions: subscriptionsReducer,
    Coupons: CouponsReducer,
    Sliders: SlidersReducer,
    Subcatbanner: SubcatbannerReducer,
    BestOffers: BestOffersReducer,
    FeaturedInWeek: FeaturedInWeekReducer,
    InfocusToday: InfocusTodayReducer,
    TodayFeatured: TodayFeaturedReducer,
    TopBanner: TopBannerReducer,
    Employees: EmployeesReducer,
    Offersbanner: OffersbannerReducer,
    Pinamounts: PinamountsReducer,
    Stocks: StocksReducer,
    Tags: TagsReducer,
    VegPage: VegPageReducer,
  },
});
