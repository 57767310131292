import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  vegHomePage: localStorage.getItem("vegHomePage")
    ? JSON.parse(localStorage.getItem("vegHomePage"))
    : [],

  isLoading: true,
  vegHomePageLoading: true,
  deletevegHomePageLoading: true,
};

export const getVegPage = createAsyncThunk(
  "VegPage/getVegPage",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/veghomepage/all`;
      const resp = await axios(url);
      return resp.data.vegHomepage;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const vegHomePageUpdate = createAsyncThunk(
  "VegPage/vegHomePageUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/veghomepage/${formData.homepageId}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("VegPage Not create");
    }
  }
);

const VegPageSlice = createSlice({
  name: "VegPage",
  initialState,
  reducers: {
    deleteVegPage(state, action) {
      state.vegHomePage = state.vegHomePage.filter(
        (home) => home._id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVegPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVegPage.fulfilled, (state, action) => {
        state.vegHomePage = action.payload;

        localStorage.setItem("vegHomePage", JSON.stringify(state.vegHomePage));
        state.isLoading = false;
        state.vegHomePageLoading = false;
      })
      .addCase(getVegPage.rejected, (state) => {
        state.isLoading = true;
      })

      .addCase(vegHomePageUpdate.pending, (state) => {
        state.vegHomePageLoading = true;
      })
      .addCase(vegHomePageUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.vegHomePage = state.vegHomePage.filter(
            (category) => category._id !== action.payload.vegHomepage._id
          );
          state.vegHomePage = [
            ...state.vegHomePage,
            action.payload.vegHomepage,
          ];

          localStorage.setItem(
            "vegHomePage",
            JSON.stringify(state.vegHomePage)
          );
        }

        state.vegHomePageLoading = false;
      })
      .addCase(vegHomePageUpdate.rejected, (state) => {
        state.vegHomePageLoading = true;
      });
  },
});

export const { deleteVegPage } = VegPageSlice.actions;
export default VegPageSlice.reducer;
