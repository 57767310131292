import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import {
  superCategoryUpdate,
  validateSlugUrl,
} from "../../../redux/SuperCategory/SuperCategorySlice";

const EditSuperCategory = () => {
  const { desktopimage, imageLoading } = useSelector((store) => store.category);
  const { superCatTotal } = useSelector((store) => store.superCategory);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categoryId, setCategoryId] = useState("");
  const [name, setName] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkSlugUrl, setCheckSlugUrl] = useState("");
  const [nameError, setNameError] = useState("");
  const [errorcolor, setErrorcolor] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [status, setStatus] = useState(false);
  const [priority, setpriority] = useState("");

  useEffect(() => {
    const getSuperCategory = async () => {
      if (params.slugurl) {
        const updatesupercat = superCatTotal.find(
          (supercat) => supercat.slugUrl === params.slugurl
        );
        console.log(updatesupercat, "1");
        if (updatesupercat !== "") {
          setCategoryId(updatesupercat._id);
          setName(updatesupercat.name);
          setCheckSlugUrl(updatesupercat.slugUrl);
          setMetaTitle(updatesupercat.metaTitle);
          setMetaKeyword(updatesupercat.metaKeyword);
          setMetaDesc(updatesupercat.metaDesc);
          setStatus(updatesupercat.status);
        }
      }
    };
    getSuperCategory();
  }, [params.slugurl]);

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setErrorcolor("green");
    setCheckSlugUrl("");
    setNameError("category available");
    const nameSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(nameSlug);

    if (nameSlug !== "") {
      let responce = await dispatch(validateSlugUrl(nameSlug));
      if (responce.payload.success) {
        setNameError("super category alredy exist");
        setErrorcolor("red");
      } else if (nameSlug.length <= 2) {
        setErrorcolor("red");
        setNameError("minimum 3 letters");
        setCheckSlugUrl("");
      } else if (nameSlug.length >= 3) {
        setErrorcolor("green");
        setNameError("");
        setCheckSlugUrl(nameSlug);
      }
    }
  };

  const handleSuperCategory = async (e) => {
    const formData = {
      _id: categoryId,
      name: name,
      slugUrl: checkSlugUrl,
      mobileImage: desktopimage,
      desktopImage: desktopimage,
      metaTitle: metaTitle,
      metaKeyword: metaKeyword,
      metaDesc: metaDesc,
      status: status,
      priority: priority,
    };
    console.log(formData, "formData");
    const resp = await dispatch(superCategoryUpdate(formData));
    if (resp.payload.success) {
      alert("Super Category Updated");
      setName("");
      setMetaDesc("");
      setMetaKeyword("");
      setMetaTitle("");
      navigate("/admin/listsupersategory");
    }
  };

  // const handleReset = () => {
  //   setName("");
  //   setMetaTitle("");
  //   setMetaKeyword("");
  //   setMetaDesc("");
  // };
  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
              >
                Edit Category Details
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Name :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Name"
                        variant="outlined"
                        className={
                          nameError !== ""
                            ? "textfield error_class"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        value={name}
                        onChange={(e) => verifyslugurl(e)}
                      />
                    </div>
                  </div>
                  <div style={{ color: "red" }}>{nameError}</div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Title :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Meta Title"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={metaTitle}
                        onChange={(e) => setMetaTitle(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Keyword :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <TextField
                        label="Enter Meta Keyword"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={metaKeyword}
                        onChange={(e) => setMetaKeyword(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Desc :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Meta Desc"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={metaDesc}
                        onChange={(e) => setMetaDesc(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            {/* <button
                style={{ background: "#ef4444", outline: "none", border: "none" }}
                className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                onClick={() => handleReset()}
              >
                <i className="ph-bold ph-x text-lg" /> Reset
              </button> */}
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{ background: "#4D44B5", outline: "none", border: "none" }}
              onClick={() => handleSuperCategory()}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSuperCategory;
