import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
// import FormControl from '@mui/material/FormControl';
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import Switch from "@mui/material/Switch";
import {
  productImages,
  productPost,
} from "../../../redux/Product/ProductSlice";
const label = { inputProps: { "aria-label": "Size switch demo" } };

const AddProduct = () => {
  const dispatch = useDispatch();
  const handleChange1 = (event) => {
    setChecked(event.target.checked);
  };
  const cases = ["A", "B", "C", "D", "E"];
  // const { loginData } = useSelector((store) => store.auth)
  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { categoryList } = useSelector((store) => store.category);
  const { subCategoryList } = useSelector((store) => store.subCategory);
  const { brandtotal } = useSelector((store) => store.brand);

  const [superCategory, setSuperCategory] = useState("");
  const [superCategoryId, setSuperCategoryId] = useState("");
  const [catbySupercat, setCatbySupercat] = useState([]);
  const [brandbySupercat, setBrandbySupercat] = useState([]);

  const [brand, setBrand] = useState("");
  const [brandId, setBrandId] = useState("");

  const [category, setCategory] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [filterSubCategory, setfilterSubCategory] = useState([]);

  const [subCategoryId, setSubCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState("");

  const [textInputs, setTextInputs] = useState([{ id: Date.now(), value: "" }]);

  const [checked, setChecked] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [url, setUrl] = useState("");
  const [productInfo, setproductInfo] = useState("");
  const [about, setAbout] = useState("");
  const [ingradient, setIngradient] = useState("");
  const [rating, setRating] = useState("");
  const [ratingError, setRatingError] = useState(false);
  const [type, setType] = useState("");
  const [options, setOptions] = useState("");
  const [gst, setGst] = useState("");
  const [packSize, setPackSize] = useState("");

  const [multi, setMulti] = useState(false);
  const [single, SetSingle] = useState(true);
  const [recommends, setRecommends] = useState(false);
  const [cashback, setCashback] = useState(false);
  const [hotProducts, setHotProducts] = useState(false);
  const [trending, setTrending] = useState(false);
  const [offer, setOffer] = useState(false);
  const [outOfStack, setOutOfStack] = useState(false);
  const [prime, setPrime] = useState(false);
  const [stockAutoUpdate, setStockAutoUpdate] = useState(false);
  const [imgUrlDeskTiles, setImgUrlDeskTiles] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [gstCost, setGstCost] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [gstSellP, setGstSellP] = useState("");
  const [mrp, setMrp] = useState("");
  const [desktopImages, setDesktopImages] = useState([]);
  const [packIndex, setpackIndex] = useState(null);
  const [buttonloading, setbuttonloading] = useState(false);

  const handleSuperCatgory = (e) => {
    setSuperCategoryId(e.target.value);
    setBrandId(e.target.value);
    const getSuperCategoryName = superCatTotal.find(
      (indCat) => indCat._id === e.target.value
    );
    if (getSuperCategoryName) {
      setSuperCategory(getSuperCategoryName);
      setBrand(getSuperCategoryName);
    }
  };

  useEffect(() => {
    const catbysupercat = categoryList.filter(
      (cat) => cat.superCategoryId === superCategoryId
    );
    setCatbySupercat(catbysupercat);
  }, [superCategoryId, categoryList]);

  useEffect(() => {
    const brandbysupercat = brandtotal.filter(
      (cat) => cat.superCategoryId === superCategoryId
    );
    setBrandbySupercat(brandbysupercat);
  }, [brandtotal, superCategoryId]);

  const handleChangeBrand = (e) => {
    const value = e.target.value;
    setBrandId(value);
    const textValue = brandtotal.find((indcat) => indcat._id === value);
    if (textValue) {
      setBrand(textValue);
    }
  };

  const handleChangeCategory = (evt) => {
    const value = evt.target.value;
    setCategoryId(value);
    const textValue = categoryList.find((indcat) => indcat._id === value);
    if (textValue) {
      setCategory(textValue);
    }
  };

  useEffect(() => {
    const filterSubCategorydata = subCategoryList.filter(
      (indSubCat) => indSubCat.categoryId === categoryId
    );
    if (filterSubCategorydata) {
      setfilterSubCategory(filterSubCategorydata);
    }
  }, [categoryId]);

  const handleSubCategory = (evt) => {
    const value = evt.target.value;
    setSubCategoryId(value);
    const textValue = subCategoryList.find((indcat) => indcat._id === value);
    if (textValue) {
      setSubCategory(textValue);
    }
  };
  //  end upto subcat
  const [packSizes, setPackSizes] = useState([
    {
      packSize: "",
      imgUrl: "",
      ImgUrlMbl: "",
      ImgUrlMblIcon: "",
      ImgUrlDesk: "",
      ImgUrlDeskIcon: "",
      seriesImages: [],
      costPrc: "",
      gstCost: "",
      sellingPrice: "",
      gstSell: "",
      mrp: "",
      outofstock: false,
      prime: true,
      stockAuto: false,
    },
  ]);
  const handleRatingChange = (e) => {
    const value = e.target.value;
    if (value === "" || (value >= 1 && value <= 5)) {
      setRating(value);
      setRatingError(false);
    } else {
      setRatingError(true);
    }
  };

  const handlePackChange = (index, field, value) => {
    let updatedPackSizes = [...packSizes];
    if (field === "prime" && value === true) {
      updatedPackSizes = updatedPackSizes.map((pack, i) => ({
        ...pack,
        prime: i === index ? true : false, // Only the selected pack will have prime as true
      }));
    } else if (field === "seriesImages") {
      const files = Array.from(value.target.files);
      const imagePromises = files.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = async () => {
            if (reader.readyState === 2) {
              resolve(reader.result);
            }
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(imagePromises).then((images) => {
        updatedPackSizes[index][field] = images;
        updatedPackSizes[index].imgUrl = 0;
        updatedPackSizes[index].ImgUrlMbl = images[0];
        updatedPackSizes[index].ImgUrlMblIcon = images[0];
        updatedPackSizes[index].ImgUrlDesk = images[0];
        updatedPackSizes[index].ImgUrlDeskIcon = images[0];
      });
    } else {
      updatedPackSizes[index][field] = value;
    }
    setPackSizes(updatedPackSizes);
  };

  const handleSelectThumbnail = (index, imageIndex) => {
    let updatedPackSizes = [...packSizes];
    updatedPackSizes[index].imgUrl = imageIndex;
    updatedPackSizes[index].ImgUrlMbl =
      updatedPackSizes[index].seriesImages[imageIndex];
    updatedPackSizes[index].ImgUrlMblIcon =
      updatedPackSizes[index].seriesImages[imageIndex];
    updatedPackSizes[index].ImgUrlDesk =
      updatedPackSizes[index].seriesImages[imageIndex];
    updatedPackSizes[index].ImgUrlDeskIcon =
      updatedPackSizes[index].seriesImages[imageIndex];
    setPackSizes(updatedPackSizes);
  };

  const handleImage = async (event, index) => {
    const file = event.target.files[0];
    if (!file) return;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "KiranaWorld"); // Replace with your Cloudinary upload preset
    const response = await axios.post(
      "https://api.cloudinary.com/v1_1/daoms6kea/image/upload", // Replace with your Cloudinary cloud name
      formData
    );
    handlePackChange(index, "imgUrl", response.data.secure_url);
  };

  const addNewPackSize = () => {
    let products = [...packSizes];
    const prime_index = products.findIndex((data) => data.prime === true);
    setpackIndex(prime_index);
    if (
      products[prime_index].packSize !== "" &&
      products[prime_index].costPrc !== "" &&
      products[prime_index].gstCost !== "" &&
      products[prime_index].sellingPrice !== "" &&
      products[prime_index].gstSell !== "" &&
      products[prime_index].mrp !== "" &&
      products[prime_index].seriesImages.length > 0 &&
      products[prime_index].imgUrl !== ""
    ) {
      products = products.map((data) => {
        if (data._id === products[prime_index]._id) {
          data.prime = false;
        }
        return data;
      });
      setPackSizes([
        ...products,
        {
          id: Date.now().toString(),
          packSize: "",
          imgUrl: "",
          ImgUrlMbl: "",
          ImgUrlMblIcon: "",
          ImgUrlDesk: "",
          ImgUrlDeskIcon: "",
          seriesImages: [],
          costPrc: "",
          gstCost: "",
          sellingPrice: "",
          gstSell: "",
          mrp: "",
          outofstock: false,
          prime: true,
          stockAuto: false,
        },
      ]);
    }
  };
  const editPackSize = (index) => {
    let products = [...packSizes];
    const prime_index = products.findIndex((data) => data.prime === true);
    setpackIndex(prime_index);
    if (
      products[prime_index].packSize !== "" &&
      products[prime_index].costPrc !== "" &&
      products[prime_index].gstCost !== "" &&
      products[prime_index].sellingPrice !== "" &&
      products[prime_index].gstSell !== "" &&
      products[prime_index].mrp !== "" &&
      products[prime_index].seriesImages.length > 0 &&
      products[prime_index].imgUrl !== ""
    ) {
      products = products.map((data) => {
        let obj = {
          ...data,
          prime: false,
        };
        data = obj;
        return data;
      });
      products = products.map((data, packindex) => {
        let object = data;
        if (String(packindex) === String(index)) {
          let obj = {
            ...data,
            prime: true,
          };
          object = obj;
        }
        return object;
      });
      setPackSizes([...products]);
    }
  };
  const deletePackSize = (index) => {
    let products = [...packSizes];
    products = products.filter((_, i) => i !== index);
    const prime_index = products.length - 1;
    setpackIndex(prime_index);
    products = products.map((data, packindex) => {
      let object = data;
      if (String(packindex) === String(prime_index)) {
        let obj = {
          ...data,
          prime: true,
        };
        object = obj;
      }
      return object;
    });
    setPackSizes([...products]);
  };

  const [imageInputs, setImageInputs] = useState([
    { id: Date.now(), url: null },
  ]);

  const handleInputChange = (index, event) => {
    const updatedInputs = [...textInputs];
    updatedInputs[index].value = event.target.value;
    setTextInputs(updatedInputs);
  };

  const addInputChange = () => {
    setTextInputs([...textInputs, { id: Date.now(), value: "" }]);
  };

  const removeInputChange = (index) => {
    const updatedInputs = textInputs.filter((_, i) => i !== index);
    setTextInputs(updatedInputs);
  };

  const imageChange = (index, e) => {
    const files = Array.from(e.target.files);
    const imagePromises = files.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.readyState === 2) {
            const getImage = await dispatch(
              productImages({ desktopImage: reader.result })
            );

            resolve(reader.result);
          }
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(imagePromises).then((images) => {
      // Update your state to store the multiple images
      setDesktopImages(images);
    });
  };

  const handleProduct = async () => {
    setbuttonloading(true);
    let products = [...packSizes];
    const prime_index = products.findIndex((data) => data.prime === true);
    setpackIndex(prime_index);
    let newPackSizes = [];
    const arrangePackSize = packSizes.map((indPack) => {
      let indexPack = {
        PackSize: indPack.packSize,
        ImgUrlMbl: indPack.ImgUrlMbl,
        ImgUrlMblIcon: indPack.ImgUrlMblIcon,
        ImgUrlDesk: indPack.ImgUrlDesk,
        ImgUrlDeskIcon: indPack.ImgUrlDeskIcon,
        seriesImages: indPack.seriesImages,
        CostPrc: Number(indPack.costPrc),
        GstCost: Number(indPack.gstCost),
        SellingPrice: Number(indPack.sellingPrice),
        GstSellPrc: Number(indPack.gstSell),
        Mrp: Number(indPack.mrp),
        OutOfStock: indPack.outofstock,
        Prime: indPack.prime,
        StockAutoUpdate: indPack.stockAuto,
      };
      newPackSizes.push(indexPack);
    });
    let randomNumber = "";
    for (let i = 0; i < 4; i++) {
      randomNumber += Math.floor(Math.random() * 10);
    }
    randomNumber = parseInt(randomNumber);
    const names = textInputs.map((indInput) => indInput.value);

    const formData = {
      ItemName: name,
      ProductId: `KG${randomNumber}`,
      Url: `${category.name}-${subCategory.name}-${name}`,
      superCategoryId: superCategoryId,
      superCategory: superCategory.name,
      superCategoryUrl: superCategory.slugUrl,
      Category: category.name,
      CatId: categoryId,
      categoryUrl: category.slugUrl,
      SubCat: subCategory.name,
      SubCatId: subCategoryId,
      SubCategoryUrl: subCategory.slugUrl,
      Brand: brand.name,
      BrandId: brandId,
      BrandUrl: brand.slugUrl,
      KeyWords: names,
      Description: metaDesc,
      Title: metaTitle,
      About: about,
      Ingredient: ingradient,
      ProductInfo: productInfo,
      Type: type,
      Rating: "4.5",
      Options: options,
      Recommends: recommends,
      HotProducts: hotProducts,
      Trending: trending,
      Offers: offer,
      Multi: multi,
      Single: single,
      PackSizes: newPackSizes,
    };
    const response = await dispatch(productPost(formData));

    if (response.payload.success) {
      alert(`Product With Name:${name} is created`);
      setName("");
      setMetaTitle("");
      setMetaDesc("");
      setSuperCategory("");
      setCategory("");
      setCategoryId("");
      setSubCategoryId("");
      setSubCategory("");
      setBrand("");
      setproductInfo("");
      setAbout("");
      setIngradient("");
      setRating("");
      setType("");
      setOptions("");
      setMulti(false);
      setRecommends(false);
      SetSingle(true);
      setHotProducts(false);
      setTrending(false);
      setOffer(false);
      setTextInputs([{ id: Date.now(), value: "" }]);
      setPackSizes([
        {
          packSize: "",
          imgUrl: "",
          seriesImages: [],
          costPrc: "",
          gstCost: "",
          sellingPrice: "",
          gstSell: "",
          mrp: "",
          outofstock: false,
          prime: true,
          stockAuto: false,
        },
      ]);
      setpackIndex(null);
    }
    setbuttonloading(false);
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
              >
                Add Product Details
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Super Category:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <div style={{ width: "100%" }}>
                        <Select
                          labelId="super-category-label"
                          className="textfield"
                          label="Super Category"
                          id="demo-simple-select"
                          inputProps={{ style: { textAlign: "center" } }}
                          value={superCategoryId}
                          onChange={(e) => handleSuperCatgory(e)}
                        >
                          {superCatTotal &&
                            superCatTotal.map((supcat, index) => (
                              <MenuItem key={index} value={supcat._id}>
                                {supcat.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Brand:
                    </label>
                    <div style={{ width: "80%" }}>
                      <FormControl
                        fullWidth
                        style={{ marginTop: "12px" }}
                        className="textfield"
                      >
                        <InputLabel
                          id="demo-simple-Case-Type"
                          className="inputcenter"
                        >
                          Select Brand
                        </InputLabel>
                        <Select
                          labelId="demo-simple-Case-Type"
                          id="demo-simple-select"
                          label="Select Category"
                          style={{ height: "35px" }}
                          inputProps={{ style: { textAlign: "center" } }}
                          //   value={caseid}
                          onChange={(e) => handleChangeBrand(e)}
                        >
                          {brandbySupercat.map((indCat, index) => (
                            <MenuItem value={indCat._id} key={index}>
                              {indCat.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Category:
                    </label>
                    <div style={{ width: "80%" }}>
                      <FormControl
                        fullWidth
                        style={{ marginTop: "12px" }}
                        className="textfield"
                      >
                        <InputLabel
                          id="demo-simple-Case-Type"
                          className="inputcenter"
                        >
                          Select Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-Case-Type"
                          id="demo-simple-select"
                          // value={caseType}
                          label="Select Category"
                          onChange={(e) => handleChangeCategory(e)}
                          style={{ height: "35px" }}
                          inputProps={{ style: { textAlign: "center" } }}
                        >
                          {catbySupercat.map((indCat, index) => (
                            <MenuItem value={indCat._id} key={index}>
                              {indCat.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Sub Category:
                    </label>
                    <div style={{ width: "80%" }}>
                      <FormControl
                        fullWidth
                        style={{ marginTop: "12px" }}
                        className="textfield"
                      >
                        <InputLabel
                          id="demo-simple-Case-Type"
                          className="inputcenter"
                        >
                          Select Sub Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-Case-Type"
                          id="demo-simple-select"
                          // value={caseType}
                          label="Select Sub Category"
                          onChange={(e) => handleSubCategory(e)}
                          style={{ height: "35px" }}
                          inputProps={{ style: { textAlign: "center" } }}
                        >
                          {filterSubCategory.map((test, index) => (
                            <MenuItem value={test._id} key={index}>
                              {test.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Name :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Name"
                        variant="outlined"
                        className={
                          nameError !== ""
                            ? "textfield error_class"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        value={name}
                        onChange={(e) =>
                          setName(
                            e.target.value.replace(/\b\w/g, (char) =>
                              char.toUpperCase()
                            )
                          )
                        }
                        // className={classes.textfield}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      About:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter About"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={about}
                        onChange={(e) =>
                          setAbout(
                            e.target.value.replace(/\b\w/g, (char) =>
                              char.toUpperCase()
                            )
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Ingredient*:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Ingradient"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={ingradient}
                        onChange={(e) =>
                          setIngradient(
                            e.target.value.replace(/\b\w/g, (char) =>
                              char.toUpperCase()
                            )
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Rating:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Rating"
                        variant="outlined"
                        className="textfield"
                        type="number"
                        id="text"
                        value={rating}
                        onChange={handleRatingChange}
                        inputProps={{
                          min: "1",
                          max: "5",
                          step: "1",
                        }}
                        error={ratingError}
                        helperText={
                          ratingError ? "Rating must be between 1 and 5" : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Product Info :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Product Info"
                        variant="outlined"
                        className="textfield"
                        multiline
                        rows={2}
                        type="text"
                        id="text"
                        value={productInfo}
                        onChange={(e) =>
                          setproductInfo(
                            e.target.value.replace(/\b\w/g, (char) =>
                              char.toUpperCase()
                            )
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Description :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Description"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        multiline
                        rows={2}
                        value={metaDesc}
                        onChange={(e) =>
                          setMetaDesc(
                            e.target.value.replace(/\b\w/g, (char) =>
                              char.toUpperCase()
                            )
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Meta Title:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Meta Title"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={metaTitle}
                        onChange={(e) =>
                          setMetaTitle(
                            e.target.value.replace(/\b\w/g, (char) =>
                              char.toUpperCase()
                            )
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Type:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Product Type"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={type}
                        onChange={(e) =>
                          setType(
                            e.target.value.replace(/\b\w/g, (char) =>
                              char.toUpperCase()
                            )
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Options:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Option"
                        variant="outlined"
                        className="textfield"
                        type="text"
                        id="text"
                        value={options}
                        onChange={(e) =>
                          setOptions(
                            e.target.value.replace(/\b\w/g, (char) =>
                              char.toUpperCase()
                            )
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Keywords:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      {textInputs.map((input, index) => (
                        <div key={input.id} style={{ marginBottom: "10px" }}>
                          <input
                            type="text"
                            placeholder="Enter Keywords"
                            value={input.value}
                            onChange={(e) => handleInputChange(index, e)}
                            style={{ marginRight: "10px" }}
                          />
                          {index === textInputs.length - 1 && (
                            <button
                              onClick={addInputChange}
                              style={{
                                marginRight: "5px",
                                backgroundColor: "green",
                                color: "#fff",
                                padding: "5px 10px",
                                borderRadius: "2px",
                              }}
                            >
                              +
                            </button>
                          )}
                          {textInputs.length > 1 && (
                            <button
                              onClick={() => removeInputChange(index)}
                              style={{
                                backgroundColor: "red",
                                color: "#fff",
                                padding: "5px 10px",
                                borderRadius: "2px",
                              }}
                            >
                              -
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* <div className="space-y-1">
                  <div>
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Upload Series Image:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      {imageInputs.map((input, index) => (
                        <div
                          key={input.id}
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="file"
                            onChange={(e) => handleImageChange(index, e)}
                            style={{ marginRight: "10px" }}
                          />
                          {input.url && (
                            <img
                              src={input.url}
                              alt="Selected"
                              style={{
                                width: "60px",
                                height: "60px",
                                marginRight: "10px",
                              }}
                            />
                          )}
                          {index === imageInputs.length - 1 && (
                            <button
                              onClick={addInput}
                              style={{
                                marginRight: "5px",
                                backgroundColor: "green",
                                color: "#fff",
                                padding: "5px 10px",
                                borderRadius: "2px",
                              }}
                            >
                              +
                            </button>
                          )}
                          {imageInputs.length > 1 && (
                            <button
                              onClick={() => removeInput(index)}
                              style={{
                                backgroundColor: "red",
                                color: "#fff",
                                padding: "5px 10px",
                                borderRadius: "2px",
                              }}
                            >
                              -
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div> */}

                {/* <div className="space-y-1">
                  <div>
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Grocery Image:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "100%" }}
                    >
                      {imageInputs.map((input, index) => (
                        <>
                          <div
                            key={input.id}
                            style={{
                              marginBottom: "10px",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="file"
                              multiple
                              placeholder="No File Choosen"
                              // onChange={(e) => handleImageChange(index, e)}
                              onChange={(e) => imageChange(index, e)}
                              style={{ marginRight: "10px" }}
                              required
                            />
                          </div>
                          <p>
                            * The image width and height should be 450px * 450px
                          </p>
                          <div>
                            {isgrocerydeskimageLoading ? (
                              <div></div>
                            ) : (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    // justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {desktopImages.map((image, idx) => (
                                    <div
                                      style={{
                                        width: "20%",
                                      }}
                                    >
                                      <img
                                        key={idx}
                                        src={image}
                                        height={100}
                                        alt={`img-${idx}`}
                                      />
                                    </div>
                                  ))}
                                </div>

                      
                              </>
                            )}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="grid md:grid-cols-3 gap-2">
                <div className="space-y-1">
                  <div className="form-flex">
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={recommends}
                            onChange={(e) => setRecommends(e.target.checked)}
                          />
                        }
                        label={
                          <Typography sx={{ color: "#4D44B5" }}>
                            Recommends
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <Switch
                        {...label}
                        checked={multi ? true : false}
                        onChange={() => {
                          setMulti(!multi);
                          if (!multi) {
                            SetSingle(false);
                          } else {
                            SetSingle(true);
                          }
                        }}
                      />
                      <span style={{ color: "#4D44B5", fontWeight: "500" }}>
                        Multi
                      </span>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <Switch
                        {...label}
                        checked={single ? true : false}
                        onChange={() => {
                          SetSingle(!single);
                          if (!single) {
                            setMulti(false);
                          } else {
                            setMulti(true);
                          }
                        }}
                      />
                      <span style={{ color: "#4D44B5", fontWeight: "500" }}>
                        Single
                      </span>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={(e) => handleChange1(e)}
                          />
                        }
                        label={
                          <Typography sx={{ color: "#4D44B5" }}>
                            Cashback
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={hotProducts}
                            checked={hotProducts ? true : false}
                            onChange={(e) => setHotProducts(e.target.checked)}
                          />
                        }
                        label={
                          <Typography sx={{ color: "#4D44B5" }}>
                            Hot Products
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={trending}
                            checked={trending ? true : false}
                            onChange={(e) => setTrending(e.target.checked)}
                          />
                        }
                        label={
                          <Typography sx={{ color: "#4D44B5" }}>
                            Trending
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={offer}
                            checked={offer ? true : false}
                            onChange={(e) => setOffer(e.target.checked)}
                          />
                        }
                        label={
                          <Typography sx={{ color: "#4D44B5" }}>
                            offer
                          </Typography>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-5 border-t border-dashed border-default-200">
            {packSizes.map((indPack, index) => (
              <>
                <div style={{ marginTop: "50px" }}>
                  <h1
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "#4D44B5",
                      fontSize: "1rem",
                    }}
                  >
                    Pack Size-{index + 1}
                  </h1>
                </div>
                <div className="grid md:grid-cols-2 gap-4">
                  <div className="space-y-1">
                    <div className="form-flex">
                      <label
                        className="text-sm font-medium"
                        style={{ fontWeight: "600", color: "#000066" }}
                      >
                        Pack Size:
                      </label>
                      <div
                        className="textfieldcontainer"
                        style={{ width: "80%" }}
                      >
                        <TextField
                          label="Pack Size"
                          variant="outlined"
                          value={indPack.packSize}
                          onChange={(e) =>
                            handlePackChange(index, "packSize", e.target.value)
                          }
                          className={
                            packIndex === index && indPack?.packSize === ""
                              ? "input_error_"
                              : ""
                          }
                          disabled={!indPack?.prime ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div className="form-flex">
                      <label
                        htmlFor="fName"
                        className="text-sm font-medium text-default-900"
                        style={{ fontWeight: "600", color: "#000066" }}
                      >
                        Images :
                      </label>

                      {indPack?.prime && (
                        <>
                          <div
                            className="textfieldcontainer"
                            style={{ width: "80%" }}
                          >
                            {" "}
                            <input
                              type="file"
                              multiple
                              // onChange={(e) => handleImage(e, index)}
                              onChange={(e) =>
                                handlePackChange(index, "seriesImages", e)
                              }
                              style={{ marginRight: "10px" }}
                              className={
                                packIndex === index &&
                                indPack?.seriesImages.length === 0
                                  ? "input_error_"
                                  : ""
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {indPack.seriesImages.map((image, idx) => (
                        <div
                          style={{
                            width: "20%",
                            padding: "5px",
                            border:
                              indPack.imgUrl === idx
                                ? "3px solid green"
                                : "none",
                          }}
                        >
                          <img
                            key={idx}
                            src={image}
                            height={100}
                            alt={`img-${idx}`}
                            onClick={() => {
                              if (indPack?.prime) {
                                handleSelectThumbnail(index, idx);
                              }
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div className="form-flex">
                      <label
                        htmlFor="fName"
                        className="text-sm font-medium text-default-900"
                        style={{ fontWeight: "600", color: "#000066" }}
                      >
                        Cost Price :
                      </label>
                      <div
                        className="textfieldcontainer"
                        style={{ width: "80%" }}
                      >
                        <TextField
                          label="Cost Price"
                          variant="outlined"
                          // className={
                          //   nameError !== ""
                          //     ? "textfield error_class"
                          //     : "textfield"
                          // }
                          type="text"
                          id="text"
                          value={indPack.costPrc}
                          onChange={(e) =>
                            handlePackChange(index, "costPrc", e.target.value)
                          }
                          // className={classes.textfield}
                          className={
                            packIndex === index && indPack?.costPrc === ""
                              ? "textfield input_error_"
                              : "textfield"
                          }
                          disabled={!indPack?.prime ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div className="form-flex">
                      <label
                        htmlFor="fName"
                        className="text-sm font-medium text-default-900"
                        style={{ fontWeight: "600", color: "#000066" }}
                      >
                        GST Cost :
                      </label>
                      <div
                        className="textfieldcontainer"
                        style={{ width: "80%" }}
                      >
                        <TextField
                          label="GST Cost"
                          variant="outlined"
                          // className={
                          //   nameError !== ""
                          //     ? "textfield error_class"
                          //     : "textfield"
                          // }
                          className={
                            packIndex === index && indPack?.gstCost === ""
                              ? "textfield input_error_"
                              : "textfield"
                          }
                          type="text"
                          id="text"
                          value={indPack.gstCost}
                          onChange={(e) =>
                            handlePackChange(index, "gstCost", e.target.value)
                          }
                          // className={classes.textfield}
                          disabled={!indPack?.prime ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div className="form-flex">
                      <label
                        htmlFor="fName"
                        className="text-sm font-medium text-default-900"
                        style={{ fontWeight: "600", color: "#000066" }}
                      >
                        Sell Price :
                      </label>
                      <div
                        className="textfieldcontainer"
                        style={{ width: "80%" }}
                      >
                        <TextField
                          label="Sell Price"
                          variant="outlined"
                          className={
                            packIndex === index && indPack?.sellingPrice === ""
                              ? "textfield input_error_"
                              : "textfield"
                          }
                          type="text"
                          id="text"
                          value={indPack.sellingPrice}
                          onChange={(e) =>
                            handlePackChange(
                              index,
                              "sellingPrice",
                              e.target.value
                            )
                          }
                          // className={classes.textfield}
                          disabled={!indPack?.prime ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div className="form-flex">
                      <label
                        htmlFor="fName"
                        className="text-sm font-medium text-default-900"
                        style={{ fontWeight: "600", color: "#000066" }}
                      >
                        Gst Sell :
                      </label>
                      <div
                        className="textfieldcontainer"
                        style={{ width: "80%" }}
                      >
                        <TextField
                          label="Gst Sell P"
                          variant="outlined"
                          className={
                            packIndex === index && indPack?.gstSell === ""
                              ? "textfield input_error_"
                              : "textfield"
                          }
                          type="text"
                          id="text"
                          value={indPack.gstSell}
                          onChange={(e) =>
                            handlePackChange(index, "gstSell", e.target.value)
                          }
                          // className={classes.textfield}
                          disabled={!indPack?.prime ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div className="form-flex">
                      <label
                        htmlFor="fName"
                        className="text-sm font-medium text-default-900"
                        style={{ fontWeight: "600", color: "#000066" }}
                      >
                        MRP :
                      </label>
                      <div
                        className="textfieldcontainer"
                        style={{ width: "80%" }}
                      >
                        <TextField
                          label="MRP"
                          variant="outlined"
                          className={
                            packIndex === index && indPack?.mrp === ""
                              ? "textfield input_error_"
                              : "textfield"
                          }
                          type="text"
                          id="text"
                          value={indPack.mrp}
                          onChange={(e) =>
                            handlePackChange(index, "mrp", e.target.value)
                          }
                          // className={classes.textfield}
                          disabled={!indPack?.prime ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1"></div>
                  <div className="space-y-1">
                    <div className="form-flex">
                      <div
                        className="textfieldcontainer"
                        style={{ width: "80%" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={indPack.outofstock}
                              onChange={(e) =>
                                handlePackChange(
                                  index,
                                  "outofstock",
                                  e.target.checked
                                )
                              }
                              disabled={!indPack?.prime ? true : false}
                            />
                          }
                          label={
                            <Typography sx={{ color: "#4D44B5" }}>
                              Out Of Stock
                            </Typography>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div className="form-flex">
                      <div
                        className="textfieldcontainer"
                        style={{ width: "80%" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={indPack?.prime === true}
                              onChange={(e) => {
                                if (!indPack?.prime) {
                                  handlePackChange(
                                    index,
                                    "prime",
                                    e.target.checked
                                  );
                                }
                              }}
                              disabled={!indPack?.prime ? true : false}
                            />
                          }
                          label={
                            <Typography sx={{ color: "#4D44B5" }}>
                              Prime
                            </Typography>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div className="form-flex">
                      <div
                        className="textfieldcontainer"
                        style={{ width: "80%" }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={indPack.stockAuto}
                              onChange={(e) =>
                                handlePackChange(
                                  index,
                                  "stockAuto",
                                  !indPack.stockAuto
                                )
                              }
                              disabled={!indPack?.prime ? true : false}
                            />
                          }
                          label={
                            <Typography sx={{ color: "#4D44B5" }}>
                              StockAutoUpdate
                            </Typography>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <div className="form-flex">
                      <div
                        className="textfieldcontainer"
                        style={{ width: "80%" }}
                      >
                        {packSizes.length - 1 === index && multi && (
                          <Button
                            sx={{
                              backgroundColor: "#4D44B5",
                              border: "2px solid white",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "#3a3a6b", // Slightly darker color on hover
                                borderColor: "white",
                              },
                            }}
                            onClick={addNewPackSize}
                          >
                            Add more
                          </Button>
                        )}
                        {packSizes.length > 0 && !indPack?.prime && (
                          <Button
                            sx={{
                              backgroundColor: "#4D44B5",
                              border: "2px solid white",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "#3a3a6b", // Slightly darker color on hover
                                borderColor: "white",
                              },
                            }}
                            onClick={() => editPackSize(index)}
                          >
                            Edit
                          </Button>
                        )}

                        {packSizes.length > 1 && (
                          <Button
                            sx={{
                              backgroundColor: "#e15631",
                              border: "2px solid white",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "red", // Slightly darker color on hover
                                borderColor: "white",
                              },
                            }}
                            onClick={() => deletePackSize(index)}
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            <button
              style={{ background: "#ef4444", outline: "none", border: "none" }}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
            >
              <i className="ph-bold ph-x text-lg" /> Reset
            </button>
            {!buttonloading ? (
              <>
                <button
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                  style={{
                    background: "#4D44B5",
                    outline: "none",
                    border: "none",
                  }}
                  onClick={() => handleProduct()}
                >
                  <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                  Submit
                </button>
              </>
            ) : (
              <>
                <button
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                  style={{
                    background: "#4D44B5",
                    outline: "none",
                    border: "none",
                  }}
                  // onClick={() => handleProduct()}
                >
                  <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                  Submiting..
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
