import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  productList: [],
  productLoading: true,
  isProductAvailable: false,
  imageLoading: true,
  mobileimage: "",
  mobileimageicon: "",
  desktopimage: [],
  desktopimageicon: "",
};
export const productPost = createAsyncThunk(
  "product/productPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/grocery/new`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data, "resp.data");
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const getProductDetails = createAsyncThunk(
  "product/getProductDetails",
  async (productId, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/grocery/singleProduct/${productId}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const validateSlugUrl = createAsyncThunk(
  "saloon/validateSlugUrl",
  async (slugUrl, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/grocery/slugUrl/${slugUrl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);
export const productAll = createAsyncThunk(
  "product/productAll",
  async (formData, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/grocery/all`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const productUpdate = createAsyncThunk(
  "product/productUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/grocery/update/${formData.ProductId}`;
      console.log(url, "url");
      const resp = await axios.put(url, formData, config);
      console.log(resp.data, "resp.data");
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const productDelete = createAsyncThunk(
  "product/productDelete",
  async (Id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/grocery/delete/${Id}`;
      const resp = await axios.delete(url);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const productImages = createAsyncThunk(
  "grocery/productImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/grocery/groceryimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);

const productSlice = createSlice({
  name: "grocery",
  initialState,
  reducers: {
    setImageNull(state, action) {
      state.imageLoading = action.payload.status;
      state.mobileimage = action.payload.image;
      state.desktopimage = action.payload.image;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(productPost.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(productPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.productList = [action.payload.category, ...state.productList];
        }
        state.productLoading = false;
      })
      .addCase(productPost.rejected, (state) => {
        state.productLoading = true;
      })
      .addCase(productAll.pending, (state) => {
        state.isProductAvailable = false;
        state.productLoading = true;
      })
      .addCase(productAll.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.productList = action.payload.grocery;
        }
        state.isProductAvailable = true;
        state.productLoading = false;
      })
      .addCase(productAll.rejected, (state) => {
        state.isProductAvailable = false;
        state.productLoading = true;
      })
      .addCase(productUpdate.pending, (state) => {
        state.isCategoryAvailable = false;
        state.categoryLoading = true;
      })
      .addCase(productUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.productList = state.productList.filter((pro) => {
            return pro._id !== action.payload.grocery._id;
          });
          state.productList = [action.payload.grocery, ...state.productList];
        }
        state.isProductAvailable = true;
        state.productLoading = false;
      })
      .addCase(productUpdate.rejected, (state) => {
        state.isProductAvailable = false;
        state.productLoading = true;
      })
      .addCase(productDelete.pending, (state) => {
        state.isProductAvailable = false;
        state.productLoading = true;
      })
      .addCase(productDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.productList = state.productList.filter((category) => {
            return category._id !== action.payload.grocery._id;
          });
        }
        state.isCategoryAvailable = true;
        state.categoryLoading = false;
      })
      .addCase(productDelete.rejected, (state) => {
        state.isCategoryAvailable = false;
        state.categoryLoading = true;
      });
    builder
      .addCase(productImages.pending, (state) => {
        state.isgrocerydeskimageLoading = true;
      })
      .addCase(productImages.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.mobileimage = action.payload.mobileimages;
          state.mobileimageicon = action.payload.mobileimageIcons;
          state.desktopimage = action.payload.desktopImages;
          state.desktopimageicon = action.payload.desktopImageIcons;
        }

        state.isgrocerydeskimageLoading = false;
        state.isgrocerydeskimageiconLoading = false;
        state.isgrocerymobileimageiconLoading = false;
        state.isgrocerymobileimageLoading = false;
      })
      .addCase(productImages.rejected, (state) => {
        state.isgrocerydeskimageLoading = true;
      })

  },
});
export const productActions = productSlice.actions;
export default productSlice.reducer;
