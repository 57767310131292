import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  pinamounttotal: localStorage.getItem("pinamounttotal")
    ? JSON.parse(localStorage.getItem("pinamounttotal"))
    : [],
  isLoading: true,
  pinAmountLoading: true,
  delpinAmountLoading: true,
};

export const getPinAmount = createAsyncThunk(
  "PinAmount/getPinAmount",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/pinamount/all`;
      const resp = await axios(url);
      return resp.data.pinamounttables;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const pinAmountPost = createAsyncThunk(
  "pinAmount/pinAmountPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/pinamount/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("pinamount Not create");
    }
  }
);

export const pinAmountUpdate = createAsyncThunk(
  "pinAmount/pinAmountUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/pinamount/${formData.pinamountid}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("pinamount Not create");
    }
  }
);

export const pinAmountDelete = createAsyncThunk(
  "pinAmount/pinAmountDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/pinamount/${id}`;
      const resp = await axios.delete(url, id, config);
      console.log(resp.data);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("pinamount Not create");
    }
  }
);

const PinAmountSlice = createSlice({
  name: "Pinamounts",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPinAmount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPinAmount.fulfilled, (state, action) => {
        state.pinamounttotal = action.payload;
        state.isLoading = false;
        state.pinAmountLoading = false;
        localStorage.setItem(
          "pinamounttotal",
          JSON.stringify(state.pinamounttotal)
        );
      })
      .addCase(getPinAmount.rejected, (state) => {
        state.isLoading = true;
      })

      .addCase(pinAmountPost.pending, (state) => {
        state.pinAmountLoading = true;
      })
      .addCase(pinAmountPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.pinamounttotal = [
            ...state.pinamounttotal,
            action.payload.pinamounttable,
          ];
          localStorage.setItem(
            "pinamounttotal",
            JSON.stringify(state.pinamounttotal)
          );
        }
        state.pinAmountLoading = false;
      })
      .addCase(pinAmountPost.rejected, (state) => {
        state.pinAmountLoading = true;
      })

      .addCase(pinAmountUpdate.pending, (state) => {
        state.pinAmountLoading = true;
      })
      .addCase(pinAmountUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.pinamounttotal = state.pinamounttotal.filter(
            (pinamt) => pinamt._id !== action.payload.pinamounttable._id
          );
          state.pinamounttotal = [
            ...state.pinamounttotal,
            action.payload.pinamounttable,
          ];
          localStorage.setItem(
            "pinamounttotal",
            JSON.stringify(state.pinamounttotal)
          );
        }
        state.pinAmountLoading = false;
      })
      .addCase(pinAmountUpdate.rejected, (state) => {
        state.pinAmountLoading = true;
      })

      .addCase(pinAmountDelete.pending, (state) => {
        state.delpinAmountLoading = true;
      })
      .addCase(pinAmountDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.pinamounttotal = state.pinamounttotal.filter(
            (pinamount) => pinamount._id !== action.payload.id
          );
          localStorage.setItem(
            "pinamounttotal",
            JSON.stringify(state.pinamounttotal)
          );
        }
        state.delpinAmountLoading = false;
      })
      .addCase(pinAmountDelete.rejected, (state) => {
        state.delpinAmountLoading = true;
      });
  },
});
export default PinAmountSlice.reducer;
