import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { productDelete } from "../../redux/Product/ProductSlice";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";
import moment from "moment";
import { updateOrder } from "../../redux/Order/OrderSlice";
import {
  clientUpdate,
  getClientByMob,
  walletTransitionPost,
} from "../../redux/client/clientSlice";
import { CSVLink } from "react-csv";
const label = { inputProps: { "aria-label": "Size switch demo" } };
const ProcessingItems = () => {
  const [orderItem, setOrderItem] = useState([]);
  const [orders, setOrders] = useState([]);
  const [temploading, setTempLoading] = useState(true);
  const [loadData, setLoadData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/order/orderprocessing`;
      const fetchOrder = await axios.get(url);
      if (fetchOrder.data.success === true) {
        const orderTotal = fetchOrder.data.orders;
        setOrders(orderTotal.reverse());
        setTempLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let itemArray = [];
    for (let index = 0; index < orders.length; index++) {
      let orderid = orders[index]._id;
      let element = orders[index].OrderProducts;
      element = element.map(
        (product) => (product = { ...product, orderId: orderid })
      );
      const itmendata = { ...element, orderid };
      itemArray.push(element);
    }
    itemArray = itemArray.flat(1);
    setOrderItem(itemArray);
  }, [orders, orderItem]);

  useEffect(() => {
    let formdata = {};
    let packsize = [];
    let exportData = [];

    for (let index = 0; index < orders.length; index++) {
      let ordeerId = orders[index]._id;
      let element = orders[index].OrderProducts;
      element = element.map(
        (product) => (product = { ...product, orderId: ordeerId })
      );
      packsize = [...packsize, ...element];
      packsize = [...packsize];
    }
    for (let index = 0; index < packsize.length; index++) {
      let element = packsize[index];

      formdata = {
        OrderId: element.orderId.slice(18),
        ProductName: element.ProductName,
        PackSize: element.PackSize,
        Brand: element.Brand,
        Price: element.Price,
        Mrp: element.Mrp,
        TotalPrice: element.TotalPrice,
        TotalMrp: element.TotalMrp,
        CatName: element.CatName,
      };

      exportData = [...exportData, formdata];
    }
    setLoadData(exportData);
  }, [orders]);
  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-3 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              Processing Orders
            </h4>
          </div>

          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "10px" }}
          >
            <CSVLink
              data={loadData}
              filename={"kw-processing-OrderId-data.csv"}
              onClick={() => {}}
            >
              <button
                className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                style={{
                  background: "#4D44B5",
                  outline: "none",
                  border: "none",
                }}
              >
                Export By OrderId
              </button>
            </CSVLink>
            <CSVLink
              data={loadData}
              filename={"kw-processing-Itemname-data.csv"}
              onClick={() => {}}
            >
              <button
                className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                style={{
                  background: "#4D44B5",
                  outline: "none",
                  border: "none",
                }}
              >
                Export By ItemName
              </button>
            </CSVLink>
            <CSVLink
              data={loadData}
              filename={"kw-processing-Brand-data.csv"}
              onClick={() => {}}
            >
              <button
                className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                style={{
                  background: "#4D44B5",
                  outline: "none",
                  border: "none",
                }}
              >
                Export By Brand
              </button>
            </CSVLink>
          </div>
        </div>

        <div className="grid grid-cols-1">
          <div
            className="shadow rounded-lg bg-white dark:bg-default-50"
            style={{ marginBottom: "80px" }}
          >
            <div className="border-t border-dashed border-default-200">
              <div className="relative overflow-x-auto">
                <table className="min-w-full overflow-x-hidden  table-striped">
                  <thead className="border-b border-dashed border-default-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        OrderID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        ItemName
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        Brand
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                      >
                        PackSize
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Qty
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        TotalPrice
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderItem &&
                      orderItem.map((indOrder, index) => (
                        <tr
                          style={{
                            background: index % 2 === 0 ? "#eee" : "#fff",
                          }}
                        >
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                          >
                            {index + 1}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-16"
                            style={{ color: "green", cursor: "pointer" }}
                          >
                            <Link
                              to={`/admin/orderdetails/${indOrder.orderId}`}
                            >
                              {indOrder.orderId.slice(18)}
                            </Link>
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-30"
                          >
                            {indOrder.ItemName}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.Brand}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.PackSize}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.Qty}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.Price}
                          </td>
                          <td
                            scope="col"
                            className="px-6 py-3 text-start text-sm capitalize font-semibold text-default-900 min-w-20"
                          >
                            {indOrder.TotalPrice}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcessingItems;
