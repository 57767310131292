import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clientUpdate,
  getClientByMob,
  walletTransitionPost,
} from "../../redux/client/clientSlice";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { MenuItem, Typography } from "@mui/material";

const WalletUpdate = () => {
  const dispatch = useDispatch();

  const [updateBoxShow, setUpdateBoxShow] = useState(false);
  const [search, setSearch] = useState("");
  const [cnfNumber, setCnfNumber] = useState("");
  const [numberError, setnumberError] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [walletamount, setWalletamount] = useState("");
  const [walletamountCnf, setWalletamountCnf] = useState("");
  const [errorColour, setErrorColour] = useState("red");

  const numberchange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setSearch(value);
    setnumberError("");
    setErrorColour("");
    if (value.length === 10) {
      setCnfNumber(value);
    } else {
      setnumberError("Please Enter Correct Number");
      setErrorColour("red");
    }
  };

  const getUserDetails = async (e) => {
    e.preventDefault();
    if (cnfNumber !== "") {
      const getClientForm = {
        Mobile: cnfNumber,
      };

      const getOrderClient = await dispatch(getClientByMob(getClientForm));
      let getclient = getOrderClient.payload.client;
      if (getclient) {
        setUserDetails(getclient);
        setUpdateBoxShow(true);
        setWalletamount("");
      }
    }
  };

  const walletamountchange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setWalletamount(value);
    if (value !== "") {
      setWalletamountCnf(value);
    }
  };
  const handlesubmit = async (e) => {
    e.preventDefault();
    const formDta = {
      WalletBalance:
        Number(walletamountCnf) + Number(userDetails.WalletBalance),
      claintid: userDetails._id,
    };
    setnumberError("");
    const data = await dispatch(clientUpdate(formDta));
    if (data.payload.success) {
      const walletForm = {
        ClientId: userDetails._id,
        previousAmount: userDetails.WalletBalance,
        availableAmount:
          Number(walletamountCnf) + Number(userDetails.WalletBalance),
        transactionAmount: Number(walletamountCnf),
        orderId: "64e318ffeb1965fb10a8de1c",
        Type: "Manual Wallet Credit",
        ClientName: userDetails.Name,
        Mobile: userDetails.Mobile,
      };
      await dispatch(walletTransitionPost(walletForm));
      setUpdateBoxShow(false);
      setErrorColour("green");
      setSearch("");
      setnumberError("user update successfully");
    }
  };

  return (
    <>
      {updateBoxShow ? (
        <>
          <div className="min-h-screen flex flex-col lg:ps-64 w-full">
            <div className="p-2 space-y-6">
              <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                <div
                  className="flex items-center justify-between py-2 px-5"
                  style={{
                    backgroundColor: "#4D44B5",
                    color: "#fff",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <h4
                    className="text-lg font-medium text-default-950 capitalize"
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Payment Status Update
                  </h4>
                </div>
                <div className="p-5 border-t border-dashed border-default-200">
                  <div className="grid md:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <div className="form-flex">
                        <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: "600", color: "#000066" }}
                        >
                          Name :
                        </label>
                        <div
                          className="textfieldcontainer"
                          style={{ width: "80%" }}
                        >
                          <TextField
                            // label="Enter  User Mobile"
                            variant="outlined"
                            className={
                              numberError !== ""
                                ? "textfield error_class"
                                : "textfield"
                            }
                            type="text"
                            id="text"
                            value={userDetails.Name}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="space-y-1">
                      <div className="form-flex">
                        <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: "600", color: "#000066" }}
                        >
                          Mobile :
                        </label>
                        <div
                          className="textfieldcontainer"
                          style={{ width: "80%" }}
                        >
                          <TextField
                            // label="Enter  User Mobile"
                            variant="outlined"
                            className={
                              numberError !== ""
                                ? "textfield error_class"
                                : "textfield"
                            }
                            type="text"
                            id="text"
                            value={userDetails.Mobile}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="space-y-1">
                      <div className="form-flex">
                        <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: "600", color: "#000066" }}
                        >
                          Available Balance :
                        </label>
                        <div
                          className="textfieldcontainer"
                          style={{ width: "80%" }}
                        >
                          <TextField
                            // label="Enter  User Mobile"
                            variant="outlined"
                            className={
                              numberError !== ""
                                ? "textfield error_class"
                                : "textfield"
                            }
                            type="text"
                            id="text"
                            value={userDetails.WalletBalance}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="space-y-1">
                      <div className="form-flex">
                        <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: "600", color: "#000066" }}
                        >
                          Wallet :
                        </label>
                        <div
                          className="textfieldcontainer"
                          style={{ width: "80%" }}
                        >
                          <TextField
                            label="Wallet"
                            variant="outlined"
                            className={
                              numberError !== ""
                                ? "textfield error_class"
                                : "textfield"
                            }
                            type="text"
                            id="text"
                            value={walletamount}
                            onChange={(e) => walletamountchange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex items-center gap-2 justify-end"
                style={{ marginBottom: "60px" }}
              >
                <button
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                  style={{
                    background: "#4D44B5",
                    outline: "none",
                    border: "none",
                  }}
                  onClick={() => {
                    setUpdateBoxShow(false);
                    setSearch("");
                  }}
                >
                  <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                  Search New User
                </button>
                <button
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                  style={{
                    background: "#4D44B5",
                    outline: "none",
                    border: "none",
                  }}
                  onClick={(e) => handlesubmit(e)}
                >
                  <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                  Update
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="min-h-screen flex flex-col lg:ps-64 w-full">
            <div className="p-2 space-y-6">
              <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                <div
                  className="flex items-center justify-between py-2 px-5"
                  style={{
                    backgroundColor: "#4D44B5",
                    color: "#fff",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <h4
                    className="text-lg font-medium text-default-950 capitalize"
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Add Amount To User Wallet
                  </h4>
                </div>
                <div className="p-5 border-t border-dashed border-default-200">
                  <div className="grid md:grid-cols-2 gap-4">
                    <div className="space-y-1">
                      <div className="form-flex">
                        <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: "600", color: "#000066" }}
                        >
                          User Mobile :
                        </label>
                        <div
                          className="textfieldcontainer"
                          style={{ width: "80%" }}
                        >
                          <TextField
                            label="Enter  User Mobile"
                            variant="outlined"
                            className={
                              numberError !== ""
                                ? "textfield error_class"
                                : "textfield"
                            }
                            type="text"
                            id="text"
                            value={search}
                            maxLength={10}
                            minLength={10}
                            onChange={(e) => numberchange(e)}
                          />
                        </div>
                      </div>
                      <div style={{ color: errorColour }}>{numberError}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="flex items-center gap-2 justify-end"
                style={{ marginBottom: "60px" }}
              >
                <button
                  className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                  style={{
                    background: "#4D44B5",
                    outline: "none",
                    border: "none",
                  }}
                  onClick={(e) => getUserDetails(e)}
                >
                  <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                  Search User
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WalletUpdate;
