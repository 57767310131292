import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  todayfeaturedtotal: localStorage.getItem("todayfeaturedtotal")
    ? JSON.parse(localStorage.getItem("todayfeaturedtotal"))
    : [],
  mobileimage: "",
  desktopimage: "",
  posterimage: "",
  mobileimageLoading: true,
  desktopimageLoading: true,
  posterimageLoading: true,
  isLoading: true,
  istodayfeaturedLoading: true,
  deltodayfeaturedLoading: true,
};

export const getTodayFeatured = createAsyncThunk(
  "TodayFeatured/getTodayFeatured",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/todaysfeatured/all`;
      const resp = await axios(url);
      return resp.data.todaysFeatured;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const TodayFeaturedPost = createAsyncThunk(
  "TodayFeatured/TodayFeaturedPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/todaysfeatured/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("slider Not create");
    }
  }
);

export const DesktopImages = createAsyncThunk(
  "Desktop/DesktopImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/todaysfeatured/deskimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("DesktopImages Not create");
    }
  }
);

export const MobileImages = createAsyncThunk(
  "Mobile/MobileImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/todaysfeatured/mobileimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("MobileImages Not create");
    }
  }
);

export const MobilePosterImages = createAsyncThunk(
  "MobilePoster/MobilePosterImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/todaysfeatured/posterimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("MobilePosterImages Not create");
    }
  }
);

export const TodayFeaturedDelete = createAsyncThunk(
  "TodayFeatured/TodayFeaturedDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/todaysfeatured/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("slider Not create");
    }
  }
);

const TodayFeaturedSlice = createSlice({
  name: "TodayFeatured",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTodayFeatured.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTodayFeatured.fulfilled, (state, action) => {
        state.todayfeaturedtotal = action.payload;

        state.isLoading = false;
        state.istodayfeaturedLoading = false;

        localStorage.setItem(
          "todayfeaturedtotal",
          JSON.stringify(state.todayfeaturedtotal)
        );
      })
      .addCase(getTodayFeatured.rejected, (state) => {
        state.isLoading = true;
      })

      .addCase(TodayFeaturedPost.pending, (state) => {
        state.istodayfeaturedLoading = true;
      })
      .addCase(TodayFeaturedPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.todayfeaturedtotal = [
            ...state.todayfeaturedtotal,
            action.payload.todaysFeatured,
          ];
          localStorage.setItem(
            "todayfeaturedtotal",
            JSON.stringify(state.todayfeaturedtotal)
          );
        }
        state.istodayfeaturedLoading = false;
      })
      .addCase(TodayFeaturedPost.rejected, (state) => {
        state.istodayfeaturedLoading = true;
      })

      .addCase(DesktopImages.pending, (state) => {
        state.desktopimageLoading = true;
      })
      .addCase(DesktopImages.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.desktopimage = action.payload.desktopImages;
        }
        state.desktopimageLoading = false;
      })
      .addCase(DesktopImages.rejected, (state) => {
        state.desktopimageLoading = true;
      })

      .addCase(MobileImages.pending, (state) => {
        state.mobileimageLoading = true;
      })
      .addCase(MobileImages.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.mobileimage = action.payload.mobileImages;
        }
        state.mobileimageLoading = false;
      })
      .addCase(MobileImages.rejected, (state) => {
        state.mobileimageLoading = true;
      })

      .addCase(MobilePosterImages.pending, (state) => {
        state.posterimageLoading = true;
      })
      .addCase(MobilePosterImages.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.posterimage = action.payload.posterImages;
        }
        state.posterimageLoading = false;
      })
      .addCase(MobilePosterImages.rejected, (state) => {
        state.posterimageLoading = true;
      })

      .addCase(TodayFeaturedDelete.pending, (state) => {
        state.deltodayfeaturedLoading = true;
      })
      .addCase(TodayFeaturedDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.todayfeaturedtotal = state.todayfeaturedtotal.filter(
            (slider) => slider._id !== action.payload.id
          );
          localStorage.setItem(
            "todayfeaturedtotal",
            JSON.stringify(state.todayfeaturedtotal)
          );
        }
        state.deltodayfeaturedLoading = false;
      })
      .addCase(TodayFeaturedDelete.rejected, (state) => {
        state.deltodayfeaturedLoading = true;
      });
  },
});

export const {} = TodayFeaturedSlice.actions;
export default TodayFeaturedSlice.reducer;
