import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";

import MainLayOut from "./component/MainLayOut";
import Home from "./pages/Home";
import { useEffect } from "react";
import Login from "./pages/login/Login";
import { useDispatch, useSelector } from "react-redux";
import { categoryAll } from "./redux/Category/CategorySlice";
import { subCategoryAll } from "./redux/SubCategory/SubCategorySlice";
import { productAll } from "./redux/Product/ProductSlice";
import { getOrder } from "./redux/Order/OrderSlice";
import { superCategoryAll } from "./redux/SuperCategory/SuperCategorySlice";
import { getBrand } from "./redux/Brand/BrandSlice";
import { getCoupon } from "./redux/coupon/couponSlice";
import { getSlider } from "./redux/slider/sliderSlice";
import { getSubCatBanner } from "./redux/subCatBanner/subCatBannerSlice";
import { getStore } from "./redux/store/StoreSlice";
import { getBestOffers } from "./redux/AllBanner&Slider/BestOffersSlice";
import { getFeaturedInWeek } from "./redux/AllBanner&Slider/FeaturedInWeekSlice";
import { getInfocusToday } from "./redux/AllBanner&Slider/InFocusTodaySlice";
import { getTodayFeatured } from "./redux/AllBanner&Slider/TodayFeaturedSlice";
import { getTopBanner } from "./redux/AllBanner&Slider/TopBannerSlice";
import { getEmployees } from "./redux/emplyees/employeesSlice";
import { getOfferBanner } from "./redux/offersBanner/offersBannerSlice";
import { getPinAmount } from "./redux/pinAmount/pinAmountSlice";
import { getStock } from "./redux/stock/stockSlice";
import {
  getbannertag,
  getcategorytag,
  getuniversaltag,
} from "./redux/tags/TagSlice";
import { getVegPage } from "./redux/vegpage/VegPageSlice";
import AddSuperCategory from "./pages/admin-magangement/supercategory/AddSuperCategory";
import SuperCategoryList from "./pages/admin-magangement/supercategory/SuperCategoryList";
import EditSuperCategory from "./pages/admin-magangement/supercategory/EditSuperCategory";
import AddCategory from "./pages/admin-magangement/category/AddCategory";
import CategoryList from "./pages/admin-magangement/category/CategoryList";
import EditCategory from "./pages/admin-magangement/category/EditCategory";
import AddSubCategory from "./pages/admin-magangement/sub_category/AddSubCategory";
import SubCategoryList from "./pages/admin-magangement/sub_category/SubCategoryList";
import EditSubCategory from "./pages/admin-magangement/sub_category/EditSubCategory";
import AddBrand from "./pages/admin-magangement/brand/AddBrand";
import BrandList from "./pages/admin-magangement/brand/BrandList";
import BookedOrder from "./pages/order/BookedOrder";
import ProcessedOrder from "./pages/order/ProcessedOrder";
import DispatchedOrder from "./pages/order/DispatchedOrder";
import OutOfDeliveryOrder from "./pages/order/OutOfDeliveryOrder";
import DeliveredOrder from "./pages/order/DeliveredOrder";
import CancelledOrder from "./pages/order/CancelledOrder";
import OrderDetails from "./pages/order/OrderDetails";
import AssignOrder from "./pages/order/AssignOrder";
import OrderInvoice from "./pages/order/OrderInvoice";
import ProcessingItems from "./pages/reports/ProcessingItems";
import WalletUpdate from "./pages/reports/WalletUpdate";
import UpdatePayment from "./pages/reports/UpdatePayment";
import RemoveWalletUpdates from "./pages/reports/RemoveWalletUpdates";
import WalletHistory from "./pages/reports/WalletHistory";
import AddSlider from "./pages/sliders/AddSlider";
import ListSlider from "./pages/sliders/ListSlider";
import UpdateSlider from "./pages/sliders/UpdateSlider";
import AddProduct from "./pages/admin-magangement/product/AddProduct";
import ProductList from "./pages/admin-magangement/product/ProductList";
import UserData from "./pages/reports/UserData";
import ClientWalletHistory from "./pages/reports/ClientWalletHistory";
import ClientCartHistory from "./pages/reports/ClientCartHistory";
import UserOrderHistory from "./pages/reports/UserOrderHistory";
import AddStore from "./pages/store/AddStore";
import ListStore from "./pages/store/ListStore";
import EditStore from "./pages/store/EditStore";
import AddEmploye from "./pages/Employee/AddEmploye";
import ListEmployee from "./pages/Employee/ListEmployee";
import UpdateEmployee from "./pages/Employee/UpdateEmployee";

function ScrollToTop() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const initialSetup = async () => {
      try {
        dispatch(superCategoryAll());
        dispatch(categoryAll());
        dispatch(subCategoryAll());
        dispatch(getBrand());
        dispatch(getStore());
        dispatch(productAll());
        // dispatch(getSubscription());
        dispatch(getOrder());
        dispatch(getCoupon());
        dispatch(getSlider());
        dispatch(getSubCatBanner());
        // getBestOffers
        dispatch(getBestOffers());
        // getFeaturedInWeek
        dispatch(getFeaturedInWeek());
        // getInfocusToday
        dispatch(getInfocusToday());
        // getTodayFeatured
        dispatch(getTodayFeatured());
        // getTopBanner
        dispatch(getTopBanner());
        // getEmployees
        dispatch(getEmployees());
        // getOfferBanner
        dispatch(getOfferBanner());
        // getPinAmount
        dispatch(getPinAmount());
        // getStock
        dispatch(getStock());
        // getuniversaltag
        dispatch(getuniversaltag());
        // getcategorytag
        dispatch(getcategorytag());
        // getbannertag
        dispatch(getbannertag());
        // getVegPage
        dispatch(getVegPage());
      } catch (error) { }
    };
    initialSetup();
  }, []);

  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/admin/order-invoice" element={<OrderInvoice />} />
          <Route path="/admin" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route
              path="/admin/addsupersategory"
              element={<AddSuperCategory />}
            />
            <Route
              path="/admin/listsupersategory"
              element={<SuperCategoryList />}
            />
            <Route
              path="/admin/updatesupersategory/:slugurl"
              element={<EditSuperCategory />}
            />
            <Route path="/admin/addcategory" element={<AddCategory />} />
            <Route path="/admin/listcategory" element={<CategoryList />} />
            <Route
              path="/admin/editcategory/:slugurl"
              element={<EditCategory />}
            />
            <Route path="/admin/addsubcategory" element={<AddSubCategory />} />
            <Route
              path="/admin/listsubcategory"
              element={<SubCategoryList />}
            />
            <Route
              path="/admin/editSubCategory/:slugurl"
              element={<EditSubCategory />}
            />
            <Route path="/admin/addBrand" element={<AddBrand />} />
            <Route path="/admin/listbrand" element={<BrandList />} />

            <Route path="/admin/addProduct" element={<AddProduct />} />
            <Route path="/admin/productList" element={<ProductList />} />


            <Route path="/admin/order/bookedOrder" element={<BookedOrder />} />
            <Route
              path="/admin/order/processedOrder"
              element={<ProcessedOrder />}
            />
            <Route
              path="/admin/order/dispatchedOrder"
              element={<DispatchedOrder />}
            />
            <Route
              path="/admin/order/outOfDeliveryOrder"
              element={<OutOfDeliveryOrder />}
            />
            <Route
              path="/admin/order/deliveredOrder"
              element={<DeliveredOrder />}
            />
            <Route
              path="/admin/order/cancelledOrder"
              element={<CancelledOrder />}
            />
            <Route
              path="/admin/orderdetails/:orderid"
              element={<OrderDetails />}
            />
            <Route
              path="/admin/assign-delivery/:orderid"
              element={<AssignOrder />}
            />

            <Route
              path="/admin/processingitems"
              element={<ProcessingItems />}
            />
            <Route path="/admin/wallet-update" element={<WalletUpdate />} />
            <Route path="/admin/updatepayment" element={<UpdatePayment />} />
            <Route path="/admin/substract-wallet" element={<RemoveWalletUpdates />} />
            <Route path="/admin/wallet-history" element={<WalletHistory />} />
            <Route path="/admin/user-data" element={<UserData />} />
            <Route path="/admin/user-wallet-history/:id" element={<ClientWalletHistory />} />
            <Route path="/admin/user-cart-history/:id" element={<ClientCartHistory />} />
            <Route path="/admin/user-order-history/:id" element={<UserOrderHistory />} />

            <Route path="/admin/addslider" element={<AddSlider />} />
            <Route path="/admin/listslider" element={<ListSlider />} />
            <Route
              path="/admin/updateslider/:slugUrl"
              element={<UpdateSlider />}
            />

            <Route path="/admin/addstore" element={<AddStore />} />
            <Route path="/admin/liststore" element={<ListStore />} />
            <Route path="/admin/editstore/:storeUrl" element={<EditStore />} />

            <Route path="/admin/addemploye" element={<AddEmploye />} />
            <Route path="/admin/listemploye" element={<ListEmployee />} />
            <Route path="/admin/updateemploye/:id" element={<UpdateEmployee />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
