import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  employeesPost,
  post_Delivery_partner,
  validateEmail,
  validateMobile,
  validateValiId,
} from "../../redux/emplyees/employeesSlice";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const AddEmploye = () => {
  const { storetotal } = useSelector((store) => store.store);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [addressproof, setAddressproof] = useState("");
  const [password, setPassword] = useState("");
  const [delivery, setDelivery] = useState(Boolean(0));
  const [storemanager, setStoremanager] = useState(Boolean(0));
  const [StoreKeeper, setStoreKeeper] = useState(Boolean(0));
  const [errormassage, setErrormassage] = useState("");
  const [buttonPress, setbuttonPress] = useState(false);

  const [email, setemail] = useState("");
  const [emailvalid, setemailvalid] = useState("");
  const [emailError, setemailError] = useState("");
  const [number, setnumber] = useState("");
  const [numbervalid, setnumbervalid] = useState("");
  const [numberError, setnumberError] = useState("");
  const [licence, setlicence] = useState("");
  const [licencevalid, setlicencevalid] = useState("");
  const [licenceError, setlicenceError] = useState("");

  const [storeId, setstoreId] = useState("");
  const [stoteName, setstoteName] = useState("");
  const [stoteemail, setstoteemail] = useState("");
  const [stoteMobile, setstoteMobile] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (buttonPress === true) {
      if (emailvalid === "") {
        setemailError("Enter valid Email");
      } else {
        setemailError("");
      }
      if (numbervalid.length !== 10) {
        setnumberError("Enter valid number");
      } else {
        setnumberError("");
      }
      if (licencevalid === "") {
        setlicenceError("Enter valid number");
      } else {
        setlicenceError("");
      }
    }
  }, [buttonPress, emailvalid, numbervalid, licencevalid]);

  const handleStore = (e) => {
    setstoreId(e.target.value);
    const findStore = storetotal.find((data) => data._id === e.target.value);
    if (findStore) {
      setstoteName(findStore.storeName);
      setstoteemail(findStore.email);
      setstoteMobile(findStore.mobile);
    }
  };

  const check_email = async (e) => {
    const value = e.target.value.trim();
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    const email_Regex = /@[a-z]/;
    const emailtotalRegex = /.[a-z]{2,3}/;
    setemail(value);
    setemailError("");
    if (!email_Regex.test(value)) {
      setemailError("email address @ is missing like examplegmail.com");
    } else if (!emailtotalRegex.test(value)) {
      setemailError("email address domain is missing like example@");
    } else if (!emailRegex.test(value)) {
      setemailError("*Error! you have entered invalid email.");
    } else {
      const verifyemail = await dispatch(validateEmail(value));
      if (verifyemail.payload.success === true) {
        setemailError("Email already exits");
      } else {
        setemailvalid(value);
        setemailError("");
      }
    }
  };
  const check_Mobile = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setnumber(value);
    setnumberError("");
    if (value.length === 10) {
      const verify = await dispatch(validateMobile(value));
      if (verify.payload.success === false) {
        setnumbervalid(value);
        setnumberError("");
      } else {
        setnumbervalid("");
        setnumberError("Mobile number exist");
      }
    } else {
      setnumberError("Number must should be a 10 chars");
    }
  };
  const check_licences = async (e) => {
    const value = e.target.value.trim();
    setlicence(value);
    setlicenceError("");
    if (value !== "") {
      const verify = await dispatch(validateValiId(value));
      if (verify.payload.success === false) {
        setlicencevalid(value);
        setlicenceError("");
      } else {
        setlicencevalid("");
        setlicenceError("Id already exist");
      }
    } else {
      setlicenceError("Please enter valid Id");
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbuttonPress(true);

    if (
      name !== "" &&
      emailvalid !== "" &&
      numbervalid !== "" &&
      address !== "" &&
      password !== "" &&
      storeId !== "" &&
      licencevalid !== ""
    ) {
      const formData = {
        Dname: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        Daddress: emailvalid,
        Dmobile: numbervalid,
        Daddressproof: address,
        valid_id: licencevalid,
        Dpassword: password,
        DeliveryBoy: delivery,
        StoreKeeper: StoreKeeper,
        StoreManager: storemanager,
        storeName: stoteName,
        storeId: storeId,
      };

      const data = await dispatch(employeesPost(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Employee Added");
          if (delivery === true) {
            try {
              const pformdata = {
                name: name,
                mobile: numbervalid,
                email: emailvalid,
                valid_id: licencevalid,
                address: address,
                storeName: stoteName,
                storemobile: stoteMobile,
                storeId: storeId,
                storeemail: stoteemail,
              };
              const form_posting = await dispatch(
                post_Delivery_partner(pformdata)
              );
            } catch (error) {}
          }
          setbuttonPress(false);
          setName("");
          setAddress("");
          setDelivery(Boolean(0));
          setStoremanager(Boolean(0));
          setStoreKeeper(Boolean(0));
          setPassword("");
          setemail("");
          setemailvalid("");
          setemailError("");
          setnumber("");
          setnumbervalid("");
          setnumberError("");
          setlicence("");
          setlicencevalid("");
          setlicenceError("");
          setstoreId("");
          setstoteName("");
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    } else {
    }
  };
  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-2 space-y-6">
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div
              className="flex items-center justify-between py-2 px-5"
              style={{
                backgroundColor: "#4D44B5",
                color: "#fff",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <h4
                className="text-lg font-medium text-default-950 capitalize"
                style={{ color: "#fff", fontSize: "16px", fontWeight: "700" }}
              >
                Add Employee
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fName"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Store:
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "75%" }}
                    >
                      <div style={{ width: "100%" }}>
                        <Select
                          labelId="super-category-label"
                          className="textfield"
                          label="Store"
                          id="demo-simple-select"
                          value={storeId}
                          inputProps={{ style: { textAlign: "center" } }}
                          onChange={(e) => handleStore(e)}
                        >
                          {storetotal &&
                            storetotal.map((indCat, index) => (
                              <MenuItem key={index} value={indCat._id}>
                                {indCat.storeName}
                              </MenuItem>
                            ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Name :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Name"
                        variant="outlined"
                        className={
                          buttonPress && name === ""
                            ? "textfield input_error_"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        // error={!!errormassage}
                        // helperText={errormassage}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Email :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Email"
                        variant="outlined"
                        className={
                          buttonPress && emailvalid === ""
                            ? "textfield input_error_"
                            : "textfield"
                        }
                        error={emailError !== "" ? true : false}
                        helperText={emailError}
                        type="text"
                        id="text"
                        required
                        onChange={(e) => check_email(e)}
                        value={email}
                        // error={!!errormassage}
                        // helperText={errormassage}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Id :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Id"
                        variant="outlined"
                        className={
                          buttonPress && licencevalid === ""
                            ? "textfield input_error_"
                            : "textfield"
                        }
                        error={licenceError !== "" ? true : false}
                        helperText={licenceError}
                        type="text"
                        id="text"
                        required
                        onChange={(e) => check_licences(e)}
                        value={licence}
                        // error={!!errormassage}
                        // helperText={errormassage}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Mobile No. :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Mobile Number"
                        variant="outlined"
                        className={
                          buttonPress && numbervalid.length !== 10
                            ? "textfield input_error_"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        required
                        inputProps={{
                          minLength: 10,
                          maxLength: 10,
                        }}
                        onChange={(e) => check_Mobile(e)}
                        value={number}
                        error={numberError !== "" ? true : false}
                        helperText={numberError}
                        // error={!!errormassage}
                        // helperText={errormassage}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Address :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Address"
                        variant="outlined"
                        className={
                          buttonPress && address === ""
                            ? "textfield input_error_"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        required
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        // error={!!errormassage}
                        // helperText={errormassage}
                      />
                    </div>
                  </div>
                </div>

                <div className="space-y-1">
                  <div className="form-flex">
                    <label
                      htmlFor="fmetaTitle"
                      className="text-sm font-medium text-default-900"
                      style={{ fontWeight: "600", color: "#000066" }}
                    >
                      Password :
                    </label>
                    <div
                      className="textfieldcontainer"
                      style={{ width: "80%" }}
                    >
                      <TextField
                        label="Enter Password"
                        variant="outlined"
                        className={
                          buttonPress && password === ""
                            ? "textfield input_error_"
                            : "textfield"
                        }
                        type="text"
                        id="text"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        // error={!!errormassage}
                        // helperText={errormassage}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-1">
                  <div className="form-flex">
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Delivery"
                        checked={delivery === true}
                        onChange={(e) => {
                          setDelivery(e.target.checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="StoreKeeper"
                        checked={StoreKeeper === true}
                        onChange={(e) => {
                          setStoreKeeper(e.target.checked);
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Store Manager"
                        checked={storemanager === true}
                        onChange={(e) => {
                          setStoremanager(e.target.checked);
                        }}
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="space-y-1"></div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            {/* <button
              style={{ background: "#ef4444", outline: "none", border: "none" }}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              //   onClick={() => handleReset()}
            >
              <i className="ph-bold ph-x text-lg" /> Reset
            </button> */}
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{ background: "#4D44B5", outline: "none", border: "none" }}
              onClick={(e) => handlesubmit(e)}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEmploye;
