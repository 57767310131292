import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { MenuItem, Typography } from "@mui/material";
import moment from "moment/moment";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Button } from "@mui/material";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import "../../cart.css";
import { FaPlus, FaMinus, FaTrashAlt } from "react-icons/fa";
import { clientUpdate, getClientByMob } from "../../redux/client/clientSlice";
import { setSingleOrder, updateOrder } from "../../redux/Order/OrderSlice";

const OrderDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { categoryList } = useSelector((store) => store.category);
  const { subCategoryList } = useSelector((store) => store.subCategory);
  const { superCatTotal } = useSelector((store) => store.superCategory);

  const [temploading, setTempLoading] = useState(true);
  const [productall, setProductall] = useState([]);

  const [products, setProducts] = useState([]);
  const [orderinfo, setOrderinfo] = useState("");
  const [cartamountDetails, setCartamountDetails] = useState("");
  const [editBoxtShow, setEditBoxtShow] = useState(false);
  const [confnewQuentity, setConfnewQuentity] = useState(1);

  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [category, setCategory] = useState("");

  const [categoryid, setCategoryid] = useState("");
  const [catbysuper, setCatbysuper] = useState([]);

  const [subcatByProducts, setSubcatByProducts] = useState([]);
  const [productName, setProductName] = useState("");
  const [productId, setProductId] = useState("");
  const [selectproduct, setSelectProduct] = useState("");
  const [selectPackSize, setSelectPacksize] = useState("");

  const [productsByPack, setProductsByPack] = useState([]);
  const [packName, setPackName] = useState("");
  const [packId, setPackId] = useState("");
  const [packIndexs, setPackIndexs] = useState("");
  const handleSuperCatgory = (e) => {
    setSupercategoryid(e.target.value);
    const getSuperCategoryName = superCatTotal.find(
      (indCat) => indCat._id === e.target.value
    );
    if (getSuperCategoryName) {
      setSupercategory(getSuperCategoryName.name);
    }
  };
  const handleCatgory = (e) => {
    setCategoryid(e.target.value);
    const getCategoryName = catbysuper.find(
      (indCat) => indCat._id === e.target.value
    );
    if (getCategoryName) {
      setCategory(getCategoryName.name);
    }
  };
  const handlesubcatCatgory = (e) => {
    setSubcategoryid(e.target.value);
    const getsubCategoryName = subCat.find(
      (indCat) => indCat._id === e.target.value
    );
    if (getsubCategoryName) {
      setSubcategory(getsubCategoryName.name);
    }
  };
  const handleProduct = (e) => {
    setProductId(e.target.value);
    const getproductName = subcatByProducts.find(
      (indCat) => indCat._id === e.target.value
    );
    if (getproductName) {
      setProductName(getproductName.ItemName);
    }
  };
  const handlePackindex = (e) => {
    setPackId(e.target.value);
    const getpackname = productsByPack.find(
      (indCat) => indCat._id === e.target.value
    );
    const selectedIndex = productsByPack.findIndex(
      (indCat) => indCat._id === e.target.value
    );
    const index = Number(selectedIndex);
    setPackIndexs(index);
    if (getpackname) {
      setPackName(getpackname.PackSize);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const url = `${Baseurl}/api/v1/grocery/all`;
      const fetchOrder = await axios.get(url);
      if (fetchOrder.data.success === true) {
        setTempLoading(false);
        const grocTotal = fetchOrder.data.grocery;
        const product = [...grocTotal].sort((a, b) =>
          a.ItemName > b.ItemName ? 1 : -1
        );
        setProductall(product);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const orderId = params.orderid;

    const fetchData = async () => {
      try {
        const url = `${Baseurl}/api/v1/order/singleorderbyclient/${orderId}`;
        const orderFetch = await axios.get(url);

        try {
          if (orderFetch.data.success) {
            const orderdetails = orderFetch.data.order;
            setOrderinfo(orderdetails);
            setProducts(orderdetails.OrderProducts);
          }
        } catch (error) {}
      } catch (error) {}
    };
    fetchData();
  }, [params.orderid]);

  useEffect(() => {
    let cartTotalAmountN = 0;
    let totalAmount = 0;
    let netPayableN = 0;
    let cartTotalMrpN = 0;
    let cartTotalPriceN = 0;
    let totalMrp = 0;
    let totalprice = 0;
    let logisticsAmountN = 40;
    const totalitem = products.filter((order) => order.Status === true);

    for (let i = 0; i < totalitem.length; i++) {
      cartTotalAmountN = cartTotalAmountN + totalitem[i].TotalAmount;

      cartTotalMrpN = cartTotalMrpN + totalitem[i].TotalMrp * totalitem[i].Qty;

      totalMrp = totalMrp + totalitem[i].Mrp * totalitem[i].Qty;

      totalprice = totalprice + totalitem[i].Price * totalitem[i].Qty;

      cartTotalPriceN =
        cartTotalPriceN + totalitem[i].TotalPrice * totalitem[i].Qty;
    }

    if (cartTotalAmountN >= 500) {
      logisticsAmountN = 0;
    } else if (
      orderinfo.CurrentStatus === "Paid" ||
      orderinfo.GrandTotal === 0
    ) {
      logisticsAmountN = 40;
    } else {
      logisticsAmountN = 40;
    }
    const coupondata = orderinfo.couponDetails;
    cartTotalAmountN = cartTotalAmountN + logisticsAmountN;
    totalAmount = cartTotalAmountN - logisticsAmountN;
    netPayableN = totalAmount - orderinfo.wallet;

    const cartAmountsForm = {
      cartTotalAmount: cartTotalAmountN,
      netPayable: netPayableN,
      cartTotalMrp: cartTotalMrpN,
      cartTotalPrice: cartTotalPriceN,
      logisticsAmount: logisticsAmountN,
      totalAmount: cartTotalAmountN,
      totalMrp: totalMrp,
      totalprice: totalprice,
    };

    setCartamountDetails(cartAmountsForm);
  }, [orderinfo, products]);

  const singleOrders = async () => {
    const xyz = await dispatch(setSingleOrder(orderinfo));
    navigate("/admin/order-invoice");
  };

  useEffect(() => {
    const catbysupercat = categoryList.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbysuper(catbysupercat);
  }, [supercategoryid, categoryList]);

  useEffect(() => {
    const subcatbycat = subCategoryList.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subCategoryList]);

  useEffect(() => {
    if (subcategoryid !== "") {
      const productbysubcat = productall.filter(
        (product) => product.SubCatId === subcategoryid
      );
      setSubcatByProducts(productbysubcat);
    }
  }, [subcategoryid, productall]);

  useEffect(() => {
    if (productId !== "") {
      const productPack = productall.find(
        (product) => product._id === productId
      );
      setSelectProduct(productPack);
      setProductsByPack(productPack.PackSizes);
    }
  }, [productId, productall]);

  useEffect(() => {
    if (packId !== "") {
      const productPack = productsByPack.find((pack) => pack._id === packId);
      setSelectPacksize(productPack);
    }
  }, [packId, productsByPack]);
  const addCartQuentityClick = (e, cartProduct, cartindex) => {
    let product = [...products].filter((order) => order.Status === true);
    if (product[cartindex].Qty < 10) {
      product[cartindex].Qty = product[cartindex].Qty + 1;

      product[cartindex].TotalAmount =
        product[cartindex].Qty * product[cartindex].Price;

      setProducts([...product]);

      let cartTotalAmountN = 0;
      let totalAmount = 0;
      let netPayableN = 0;
      let cartTotalMrpN = 0;
      let cartTotalPriceN = 0;
      let totalMrp = 0;
      let totalprice = 0;
      let logisticsAmountN = 40;
      let cartproducts = product.filter((order) => order.Status === true);

      for (let i = 0; i < cartproducts.length; i++) {
        cartTotalAmountN = cartTotalAmountN + cartproducts[i].TotalAmount;

        cartTotalMrpN =
          cartTotalMrpN + cartproducts[i].TotalMrp * cartproducts[i].Qty;

        totalMrp = totalMrp + cartproducts[i].Mrp * cartproducts[i].Qty;

        totalprice = totalprice + cartproducts[i].Price * cartproducts[i].Qty;

        cartTotalPriceN =
          cartTotalPriceN + cartproducts[i].TotalPrice * cartproducts[i].Qty;
      }

      if (cartTotalAmountN >= 500) {
        logisticsAmountN = 0;
      } else if (
        orderinfo.CurrentStatus === "Paid" ||
        orderinfo.GrandTotal === 0
      ) {
        logisticsAmountN = 40;
      } else {
        logisticsAmountN = 40;
      }
      const coupondata = orderinfo.couponDetails;
      cartTotalAmountN = cartTotalAmountN + logisticsAmountN;
      totalAmount = cartTotalAmountN - logisticsAmountN;
      netPayableN = totalAmount - orderinfo.wallet;

      const cartAmountsForm = {
        cartTotalAmount: cartTotalAmountN,
        netPayable: netPayableN,
        cartTotalMrp: cartTotalMrpN,
        cartTotalPrice: cartTotalPriceN,
        logisticsAmount: logisticsAmountN,
        totalAmount: totalAmount,
        totalMrp: totalMrp,
        totalprice: totalprice,
      };

      setCartamountDetails(cartAmountsForm);
    }
  };
  const removeCartQuentityClick = (e, cartProduct, cartindex) => {
    let product = [...products].filter((order) => order.Status === true);
    if (product[cartindex].Qty > 1) {
      product[cartindex].Qty = product[cartindex].Qty - 1;

      product[cartindex].TotalAmount =
        product[cartindex].Qty * product[cartindex].Price;

      setProducts([...product]);

      let cartTotalAmountN = 0;
      let totalAmount = 0;
      let netPayableN = 0;
      let cartTotalMrpN = 0;
      let cartTotalPriceN = 0;
      let totalMrp = 0;
      let totalprice = 0;
      let logisticsAmountN = 40;
      let cartproducts = product.filter((order) => order.Status === true);

      for (let i = 0; i < cartproducts.length; i++) {
        cartTotalAmountN = cartTotalAmountN + cartproducts[i].TotalAmount;

        cartTotalMrpN =
          cartTotalMrpN + cartproducts[i].TotalMrp * cartproducts[i].Qty;

        totalMrp = totalMrp + cartproducts[i].Mrp * cartproducts[i].Qty;

        totalprice = totalprice + cartproducts[i].Price * cartproducts[i].Qty;

        cartTotalPriceN =
          cartTotalPriceN + cartproducts[i].TotalPrice * cartproducts[i].Qty;
      }

      if (cartTotalAmountN >= 500) {
        logisticsAmountN = 0;
      } else if (
        orderinfo.CurrentStatus === "Paid" ||
        orderinfo.GrandTotal === 0
      ) {
        logisticsAmountN = 40;
      } else {
        logisticsAmountN = 40;
      }
      const coupondata = orderinfo.couponDetails;
      cartTotalAmountN = cartTotalAmountN + logisticsAmountN;
      totalAmount = cartTotalAmountN - logisticsAmountN;
      netPayableN = totalAmount - orderinfo.wallet;

      const cartAmountsForm = {
        cartTotalAmount: cartTotalAmountN,
        netPayable: netPayableN,
        cartTotalMrp: cartTotalMrpN,
        cartTotalPrice: cartTotalPriceN,
        logisticsAmount: logisticsAmountN,
        totalAmount: totalAmount,
        totalMrp: totalMrp,
        totalprice: totalprice,
      };

      setCartamountDetails(cartAmountsForm);
    }
  };
  const removecartProductClick = (e, cartProduct) => {
    let product = [...products].filter((order) => order.Status === true);
    const prostatus = product;
    if (prostatus.length > 1) {
      product = product.map((order) => {
        if (order.packsizeId === cartProduct.packsizeId) {
          order.Status = false;
        }

        return order;
      });
      product = product;
      setProducts([...product]);

      let cartTotalAmountN = 0;
      let totalAmount = 0;
      let netPayableN = 0;
      let cartTotalMrpN = 0;
      let cartTotalPriceN = 0;
      let totalMrp = 0;
      let totalprice = 0;
      let logisticsAmountN = 40;
      let cartproducts = product.filter((order) => order.Status === true);

      for (let i = 0; i < cartproducts.length; i++) {
        cartTotalAmountN = cartTotalAmountN + cartproducts[i].TotalAmount;

        cartTotalMrpN =
          cartTotalMrpN + cartproducts[i].TotalMrp * cartproducts[i].Qty;
        totalMrp = totalMrp + cartproducts[i].Mrp * cartproducts[i].Qty;
        totalprice = totalprice + cartproducts[i].Price * cartproducts[i].Qty;
        cartTotalPriceN =
          cartTotalPriceN + cartproducts[i].TotalPrice * cartproducts[i].Qty;
      }

      if (cartTotalAmountN >= 500) {
        logisticsAmountN = 0;
      } else if (
        orderinfo.CurrentStatus === "Paid" ||
        orderinfo.GrandTotal === 0
      ) {
        logisticsAmountN = 40;
      } else {
        logisticsAmountN = 40;
      }
      const coupondata = orderinfo.couponDetails;
      cartTotalAmountN = cartTotalAmountN + logisticsAmountN;
      totalAmount = cartTotalAmountN - logisticsAmountN;
      netPayableN = totalAmount - orderinfo.wallet;

      const cartAmountsForm = {
        cartTotalAmount: cartTotalAmountN,
        netPayable: netPayableN,
        cartTotalMrp: cartTotalMrpN,
        cartTotalPrice: cartTotalPriceN,
        logisticsAmount: logisticsAmountN,
        totalAmount: totalAmount,
        totalMrp: totalMrp,
        totalprice: totalprice,
      };

      setCartamountDetails(cartAmountsForm);
    }
  };

  const orderUpdateClick = async () => {
    let netPayblecart = 0;
    let paidstatus = orderinfo.CurrentStatus;
    if (orderinfo.CurrentStatus === "Paid" || orderinfo.GrandTotal === 0) {
      if (
        Number(cartamountDetails.totalAmount) > Number(orderinfo.TotalAmount)
      ) {
        netPayblecart =
          Number(cartamountDetails.totalAmount) - Number(orderinfo.TotalAmount);
        paidstatus = "Partial Payment";
      } else {
        netPayblecart = 0;
        const getClientForm = {
          Mobile: orderinfo.Mobile,
        };

        const getOrderClient = await dispatch(getClientByMob(getClientForm));
        let getclient = getOrderClient.payload.client;
        const formDta = {
          WalletBalance:
            Number(getclient.WalletBalance) +
            Number(orderinfo.TotalAmount) -
            Number(cartamountDetails.totalAmount),
          claintid: orderinfo.ClientId,
        };
        dispatch(clientUpdate(formDta));
      }
    } else if (orderinfo.CurrentStatus === "Not Paid") {
      netPayblecart =
        Number(cartamountDetails.totalAmount) - Number(orderinfo.wallet);
    } else if (orderinfo.CurrentStatus === "Partial Payment") {
      netPayblecart =
        orderinfo.GrandTotal +
        Number(cartamountDetails.totalAmount) -
        Number(orderinfo.TotalAmount);
      if (netPayblecart < 0) {
        netPayblecart = 0;
        const getClientForm = {
          Mobile: orderinfo.Mobile,
        };

        const getOrderClient = await dispatch(getClientByMob(getClientForm));
        let getclient = getOrderClient.payload.client;
        const formDta = {
          WalletBalance: Number(getclient.WalletBalance) - netPayblecart,
          claintid: orderinfo.ClientId,
        };
        dispatch(clientUpdate(formDta));
      }
    }
    let produCount = products.filter((order) => order.Status === true);
    const formData = {
      orderid: orderinfo._id,
      OrderProducts: products,
      Saving: cartamountDetails.cartTotalMrp - cartamountDetails.cartTotalPrice,
      TotalAmount: Number(cartamountDetails.cartTotalAmount),
      DeliveryCharge: Number(cartamountDetails.logisticsAmount),
      GrandTotal: Number(netPayblecart),
      ProductCount: produCount.length,
      CurrentStatus: String(paidstatus),
    };
    const data = await dispatch(updateOrder(formData));
    const orderINFO = data.payload.order;
    setOrderinfo(orderINFO);
  };

  const addnewitem = async () => {
    if (productId !== "" && packName !== "") {
      const newProd = productall.find((product) => product._id === productId);
      let itemIndex = -1;
      itemIndex = products.findIndex(
        (item) => item.packsizeId === newProd.PackSizes[packIndexs]._id
      );

      if (itemIndex >= 0) {
        let product = [...products].filter((order) => order.Status === true);
        product = product.map((order) => {
          if (order.packsizeId === newProd.PackSizes[packIndexs]._id) {
            order.Qty = order.Qty + 1;
            order.Status = true;
          }
          return order;
        });
        product = product;
        setProducts([...product]);
        setPackName("");
        setPackId("");
        setPackIndexs("");
        setProductId("");
        setEditBoxtShow(false);
      } else {
        let newOrderform = {};
        newOrderform = {
          ProductId: newProd._id,
          ProductName: newProd.ItemName,
          CatName: newProd.Category,
          CatId: newProd.CatId,
          SubCat: newProd.SubCat,
          SubCatId: newProd.SubCatId,
          Brand: newProd.Brand,
          ItemName: newProd.ItemName,
          PackSize: newProd.PackSizes[packIndexs].PackSize,
          Description: newProd.Description,
          ImgUrl: newProd.PackSizes[packIndexs].ImgUrlMbl,
          Price: newProd.PackSizes[packIndexs].SellingPrice,
          Qty: 1,
          TotalAmount: newProd.PackSizes[packIndexs].SellingPrice * 1,
          Mrp: newProd.PackSizes[packIndexs].Mrp,
          TotalPrice: newProd.PackSizes[packIndexs].SellingPrice * 1,
          TotalMrp: newProd.PackSizes[packIndexs].Mrp * 1,
          Discount:
            newProd.PackSizes[packIndexs].Mrp * 1 -
            newProd.PackSizes[packIndexs].SellingPrice * 1,
          Cashback: newProd.Cashback,
          SellingPrice: newProd.PackSizes[packIndexs].SellingPrice,
          GstSellPrc: newProd.PackSizes[packIndexs].GstSellPrc,
          CostPrc: newProd.PackSizes[packIndexs].CostPrc,
          GstCost: newProd.PackSizes[packIndexs].GstCost,
          packsizeId: newProd.PackSizes[packIndexs]._id,
          maximumQuantity: newProd.PackSizes[packIndexs].maximumQuantity,
          Status: true,
        };
        let product = [...products];
        product = [...product, newOrderform];
        setProducts([...product]);
        setPackName("");
        setPackId("");
        setPackIndexs("");
        setProductId("");
        newOrderform = {};
        setEditBoxtShow(false);

        let cartTotalAmountN = 0;
        let totalAmount = 0;
        let netPayableN = 0;
        let cartTotalMrpN = 0;
        let cartTotalPriceN = 0;
        let totalMrp = 0;
        let totalprice = 0;
        let logisticsAmountN = 40;
        let cartproducts = product.filter((order) => order.Status === true);

        for (let i = 0; i < cartproducts.length; i++) {
          cartTotalAmountN = cartTotalAmountN + cartproducts[i].TotalAmount;

          cartTotalMrpN =
            cartTotalMrpN + cartproducts[i].TotalMrp * cartproducts[i].Qty;

          totalMrp = totalMrp + cartproducts[i].Mrp * cartproducts[i].Qty;

          totalprice = totalprice + cartproducts[i].Price * cartproducts[i].Qty;

          cartTotalPriceN =
            cartTotalPriceN + cartproducts[i].TotalPrice * cartproducts[i].Qty;
        }

        if (cartTotalAmountN >= 500) {
          logisticsAmountN = 0;
        } else if (
          orderinfo.CurrentStatus === "Paid" ||
          orderinfo.GrandTotal === 0
        ) {
          logisticsAmountN = 40;
        } else {
          logisticsAmountN = 40;
        }
        const coupondata = orderinfo.couponDetails;
        cartTotalAmountN = cartTotalAmountN + logisticsAmountN;
        totalAmount = cartTotalAmountN - logisticsAmountN;
        netPayableN = totalAmount - orderinfo.wallet;

        const cartAmountsForm = {
          cartTotalAmount: cartTotalAmountN,
          netPayable: netPayableN,
          cartTotalMrp: cartTotalMrpN,
          cartTotalPrice: cartTotalPriceN,
          logisticsAmount: logisticsAmountN,
          totalAmount: totalAmount,
          totalMrp: totalMrp,
          totalprice: totalprice,
        };

        setCartamountDetails(cartAmountsForm);
      }
    }
  };

  return (
    <>
      <Fragment>
        <div className="min-h-screen flex flex-col lg:ps-64 w-full">
          <div className="content-wrapper" style={{ marginBottom: "80px" }}>
            <div className="card me-2 ms-2">
              <section className="h-100" style={{ backgroundColor: "#eee" }}>
                <div className="container h-100 py-2">
                  <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-12 m-0 p-0 h-75">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <h3 className="fw-normal mb-0 text-black ms-2">
                          Order Cart
                        </h3>
                        {orderinfo.Status >= 2 && (
                          <button
                            style={{
                              backgroundColor: "#1976d2",
                              color: "#fff",
                              padding: "7px 20px",
                              borderRadius: "5px",
                              fontSize: "0.7rem",
                            }}
                            onClick={() => singleOrders()}
                          >
                            INVOICE
                          </button>
                        )}

                        {!editBoxtShow && (
                          <button
                            style={{
                              backgroundColor: "#1976d2",
                              color: "#fff",
                              padding: "7px 20px",
                              borderRadius: "5px",
                              fontSize: "0.7rem",
                              margin: "0 4vh",
                            }}
                            onClick={() => setEditBoxtShow(true)}
                          >
                            ADD NEW ITEMS
                          </button>
                        )}
                      </div>
                      {editBoxtShow === true ? (
                        <>
                          {temploading ? (
                            <>
                              {" "}
                              <div className="d-flex justify-content-center loadingMain">
                                <h4
                                  style={{
                                    fontWeight: "bold",
                                    color: "#36d7b7",
                                  }}
                                >
                                  Product is being fetched
                                </h4>
                                {/* <BeatLoader
                                  color={"#36d7b7"}
                                  loading={temploading}
                                  size={10}
                                  className="mt-2 mx-2"
                                /> */}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="p-5 border-t border-dashed border-default-200">
                                <div className="grid md:grid-cols-2 gap-4">
                                  <div className="space-y-1">
                                    <div className="form-flex">
                                      <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                        style={{
                                          fontWeight: "600",
                                          color: "#000066",
                                        }}
                                      >
                                        Super Category:
                                      </label>
                                      <div
                                        className="textfieldcontainer"
                                        style={{ width: "75%" }}
                                      >
                                        <div style={{ width: "100%" }}>
                                          <Select
                                            labelId="super-category-label"
                                            className="textfield"
                                            label="Super Category"
                                            id="demo-simple-select"
                                            inputProps={{
                                              style: { textAlign: "center" },
                                            }}
                                            value={supercategoryid}
                                            onChange={(e) =>
                                              handleSuperCatgory(e)
                                            }
                                          >
                                            {superCatTotal &&
                                              superCatTotal.map(
                                                (indCat, index) => (
                                                  <MenuItem
                                                    key={index}
                                                    value={indCat._id}
                                                  >
                                                    {indCat.name}
                                                  </MenuItem>
                                                )
                                              )}
                                          </Select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="space-y-1">
                                    <div className="form-flex">
                                      <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                        style={{
                                          fontWeight: "600",
                                          color: "#000066",
                                        }}
                                      >
                                        Category:
                                      </label>
                                      <div
                                        className="textfieldcontainer"
                                        style={{ width: "75%" }}
                                      >
                                        <div style={{ width: "100%" }}>
                                          <Select
                                            labelId="super-category-label"
                                            className="textfield"
                                            label="Super Category"
                                            id="demo-simple-select"
                                            inputProps={{
                                              style: { textAlign: "center" },
                                            }}
                                            value={categoryid}
                                            onChange={(e) => handleCatgory(e)}
                                          >
                                            {catbysuper &&
                                              catbysuper.map(
                                                (indCat, index) => (
                                                  <MenuItem
                                                    key={index}
                                                    value={indCat._id}
                                                  >
                                                    {indCat.name}
                                                  </MenuItem>
                                                )
                                              )}
                                          </Select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="space-y-1">
                                    <div className="form-flex">
                                      <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                        style={{
                                          fontWeight: "600",
                                          color: "#000066",
                                        }}
                                      >
                                        Sub Category:
                                      </label>
                                      <div
                                        className="textfieldcontainer"
                                        style={{ width: "75%" }}
                                      >
                                        <div style={{ width: "100%" }}>
                                          <Select
                                            labelId="super-category-label"
                                            className="textfield"
                                            label="Super Category"
                                            id="demo-simple-select"
                                            inputProps={{
                                              style: { textAlign: "center" },
                                            }}
                                            value={subcategoryid}
                                            onChange={(e) =>
                                              handlesubcatCatgory(e)
                                            }
                                          >
                                            {subCat &&
                                              subCat.map((indCat, index) => (
                                                <MenuItem
                                                  key={index}
                                                  value={indCat._id}
                                                >
                                                  {indCat.name}
                                                </MenuItem>
                                              ))}
                                          </Select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="space-y-1">
                                    <div className="form-flex">
                                      <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                        style={{
                                          fontWeight: "600",
                                          color: "#000066",
                                        }}
                                      >
                                        Product:
                                      </label>
                                      <div
                                        className="textfieldcontainer"
                                        style={{ width: "75%" }}
                                      >
                                        <div style={{ width: "100%" }}>
                                          <Select
                                            labelId="super-category-label"
                                            className="textfield"
                                            label="Super Category"
                                            id="demo-simple-select"
                                            inputProps={{
                                              style: { textAlign: "center" },
                                            }}
                                            value={productId}
                                            onChange={(e) => handleProduct(e)}
                                          >
                                            {subcatByProducts &&
                                              subcatByProducts.map(
                                                (indCat, index) => (
                                                  <MenuItem
                                                    key={index}
                                                    value={indCat._id}
                                                  >
                                                    {indCat.ItemName}
                                                  </MenuItem>
                                                )
                                              )}
                                          </Select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="space-y-1">
                                    <div className="form-flex">
                                      <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                        style={{
                                          fontWeight: "600",
                                          color: "#000066",
                                        }}
                                      >
                                        Packsize:
                                      </label>
                                      <div
                                        className="textfieldcontainer"
                                        style={{ width: "75%" }}
                                      >
                                        <div style={{ width: "100%" }}>
                                          <Select
                                            labelId="super-category-label"
                                            className="textfield"
                                            label="Super Category"
                                            id="demo-simple-select"
                                            inputProps={{
                                              style: { textAlign: "center" },
                                            }}
                                            value={packId}
                                            onChange={(e) => handlePackindex(e)}
                                          >
                                            {productsByPack &&
                                              productsByPack.map(
                                                (indCat, index) => (
                                                  <MenuItem
                                                    key={index}
                                                    value={indCat._id}
                                                  >
                                                    {indCat.PackSize}
                                                  </MenuItem>
                                                )
                                              )}
                                          </Select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="flex items-center gap-2 justify-end"
                                style={{ marginBottom: "60px" }}
                              >
                                {confnewQuentity > 0 ? (
                                  <>
                                    <button
                                      className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                                      style={{
                                        background: "#4D44B5",
                                        outline: "none",
                                        border: "none",
                                      }}
                                      onClick={() => addnewitem()}
                                    >
                                      <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                                      ADD ITEMS
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                                      style={{
                                        background: "#4D44B5",
                                        outline: "none",
                                        border: "none",
                                      }}
                                    >
                                      <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                                      ADD ITEMS
                                    </button>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {products &&
                        products
                          .filter((order) => order.Status === true)
                          .map((cartPro, cartindex) => (
                            <div
                              className="card rounded-3 mb-1"
                              key={cartPro._id}
                              style={{ backgroundColor: "#fff", width: "78vw" }}
                            >
                              <div className="container p-4 mt-0 ">
                                <div className="row  total-div">
                                  <div className=" col-md-2 col-lg-3 col-xl-2 sub_child ">
                                    <img
                                      style={{ width: "180px" }}
                                      src={cartPro.ImgUrl}
                                      className="img-fluid blur-up  "
                                    />
                                  </div>
                                  <div className="col-md-3 col-lg-3 col-xl-3  Product-info">
                                    <p className="lead fw-normal m-0 product-font ">
                                      <div
                                        style={{
                                          fontSize: "16px",
                                          width: "180px",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        {cartPro.ItemName}
                                      </div>
                                      <div
                                        style={{
                                          fontSize: "16px",
                                          width: "180px",
                                          flexWrap: "wrap",
                                        }}
                                        className="mt-2"
                                      >
                                        Pack Size : {cartPro.PackSize}
                                      </div>
                                    </p>
                                  </div>
                                  <div className="col-md-3 col-lg-2 col-xl-3  price-ifo">
                                    <h5 className="price-margin">
                                      Price : ₹ {cartPro.Price}
                                    </h5>
                                    <h5 className="price-margin">
                                      MRP : ₹ {cartPro.Mrp}
                                    </h5>
                                    <h5 style={{ color: "#0e947a" }}>
                                      Total Amount : ₹ {cartPro.TotalAmount}
                                    </h5>
                                  </div>
                                  <div className="col-md-2 col-lg-2 col-xl-2 Add-to-cart">
                                    {/* <button
                                      className="btn btn-link  button1"
                                      onClick={(e) =>
                                        removeCartQuentityClick(
                                          e,
                                          cartPro,
                                          cartindex
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "#000",
                                      }}
                                      disabled={
                                        orderinfo.Status === 5 &&
                                        orderinfo.Status === 0
                                          ? true
                                          : ""
                                      }
                                    >
                                      <FaMinus color="red" size={18} />
                                    </button> */}
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "5vh",
                                        background: "#E3E0C9",
                                        width: "5vh",
                                        marginTop: "0.5vh",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        removeCartQuentityClick(
                                          e,
                                          cartPro,
                                          cartindex
                                        )
                                      }
                                    >
                                      <FaMinus color="#000" size={12} />
                                    </div>
                                    <input
                                      // id="form1"
                                      type="text"
                                      name="quantity"
                                      value={cartPro.Qty}
                                      contentEditable="false"
                                      readOnly="readOnly"
                                      className="form-control forminputs form-control-sm"
                                      style={{
                                        backgroundColor: "transparent",
                                        width: "8vh",
                                      }}
                                    />
                                    {/* <button
                                      className="btn btn-link  button1"
                                      onClick={(e) =>
                                        addCartQuentityClick(
                                          e,
                                          cartPro,
                                          cartindex
                                        )
                                      }
                                      disabled={
                                        orderinfo.Status === 5 &&
                                        orderinfo.Status === 0
                                          ? true
                                          : ""
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      
                                    </button> */}
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "5vh",
                                        background: "#E3E0C9",
                                        width: "5vh",
                                        marginTop: "0.5vh",
                                        borderRadius: "50%",
                                        cursor: "pointer",
                                      }}
                                      onClick={(e) =>
                                        addCartQuentityClick(
                                          e,
                                          cartPro,
                                          cartindex
                                        )
                                      }
                                    >
                                      <FaPlus color="#000" size={12} />
                                    </div>
                                  </div>
                                  {orderinfo.Status === 5 &&
                                  orderinfo.Status === 0 ? (
                                    <>
                                      <div className="col-md-1 col-lg-1 col-xl-1 text-end  Remove">
                                        <a
                                          className="text-danger "
                                          style={{ cursor: "pointer" }}
                                        >
                                          <FaTrashAlt color="red" size={18} />{" "}
                                          <button
                                            type="button"
                                            className="mobileView text-danger"
                                          >
                                            Remove
                                          </button>
                                        </a>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        className="col-md-1 col-lg-1 col-xl-1 text-end  Remove"
                                        onClick={(e) =>
                                          removecartProductClick(
                                            e,
                                            cartPro,
                                            cartindex
                                          )
                                        }
                                      >
                                        <a
                                          className="text-danger "
                                          style={{ cursor: "pointer" }}
                                        >
                                          <FaTrashAlt color="red" size={18} />{" "}
                                          <button
                                            type="button"
                                            className="mobileView text-danger"
                                          >
                                            Remove
                                          </button>
                                        </a>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      <div className="card rounded-3 mb-1 ">
                        <ul className="">
                          <li
                            className="mb-0"
                            style={{
                              listStyle: "none",
                              fontSize: "18px",
                              fontWeight: "400",
                            }}
                          >
                            Total MRP :
                          </li>
                          <li
                            className="mb-0"
                            style={{
                              listStyle: "none",
                              fontSize: "18px",
                              fontWeight: "400",
                            }}
                          >
                            Sub-Total :
                          </li>
                          <li
                            className="mb-0"
                            style={{
                              listStyle: "none",
                              fontSize: "18px",
                              fontWeight: "400",
                            }}
                          >
                            Shipping :
                          </li>

                          <li
                            className="mb-0"
                            style={{
                              listStyle: "none",
                              fontSize: "18px",
                              fontWeight: "400",
                            }}
                          >
                            Wallet Used :
                          </li>
                          <li
                            className="mb-0"
                            style={{
                              listStyle: "none",
                              fontSize: "18px",
                              fontWeight: "400",
                            }}
                          >
                            Grand Total :
                          </li>
                        </ul>
                        <ul className="mr-3">
                          <li
                            className="mb-0"
                            style={{
                              listStyle: "none",
                              fontSize: "18px",
                              fontWeight: "400",
                            }}
                          >
                            ₹ {cartamountDetails.totalMrp}
                          </li>
                          <li
                            className="mb-0"
                            style={{
                              listStyle: "none",
                              fontSize: "18px",
                              fontWeight: "400",
                            }}
                          >
                            ₹ {cartamountDetails.cartTotalAmount}
                          </li>
                          <li
                            className="mb-0"
                            style={{
                              listStyle: "none",
                              fontSize: "18px",
                              fontWeight: "400",
                            }}
                          >
                            ₹ {cartamountDetails.logisticsAmount}
                          </li>
                          <li
                            className="mb-0"
                            style={{
                              listStyle: "none",
                              fontSize: "18px",
                              fontWeight: "400",
                            }}
                          >
                            ₹ {orderinfo.wallet}
                          </li>
                          <li
                            className="mb-0"
                            style={{
                              listStyle: "none",
                              fontSize: "18px",
                              fontWeight: "400",
                            }}
                          >
                            ₹{" "}
                            {cartamountDetails.cartTotalAmount -
                              orderinfo.wallet}
                          </li>
                        </ul>
                      </div>
                      {orderinfo.Status === 5 && orderinfo.Status === 0 ? (
                        <>
                          <div className="card " style={{ height: "80px" }}>
                            <button
                              type="button"
                              className="btn btn-warning btn-block btn-lg"
                            >
                              Proceed to Order Update
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="button_card "
                            style={{ height: "80px" }}
                          >
                            <button
                              type="button"
                              className="btn btn-warning btn-block btn-lg"
                              onClick={() => orderUpdateClick()}
                            >
                              Proceed to Order Update
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default OrderDetails;
