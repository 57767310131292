import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { json } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
const initialState = {
  superCatTotal: localStorage.getItem("superCatTotal")
    ? JSON.parse(localStorage.getItem("superCatTotal"))
    : [],
  superCategoryLoading: true,
  delSupercatLoading: true,
  isSuperCategoryAvailable: false,
};

export const superCategoryPost = createAsyncThunk(
  "superCategory/superCategoryPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/superCategory/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("SuperCategory Not create");
    }
  }
);

export const validateSlugUrl = createAsyncThunk(
  "superCategory/validateSlugUrl",
  async (slugUrl, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/superCategory/slugUrl/${slugUrl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const superCategoryAll = createAsyncThunk(
  "superCategory/categoryAll",
  async (formData, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/superCategory/all`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      // return error.response.data;
      return thunkAPI.rejectWithValue("SuperCategory Not create");
    }
  }
);
export const superCategoryUpdate = createAsyncThunk(
  "superCategory/superCategoryUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/superCategory/update/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      console.log(resp.data, "resp.data");
      return resp.data;
    } catch (error) {
      // return error.response.data;
      return thunkAPI.rejectWithValue("SuperCategory Not create");
    }
  }
);

export const superCategoryDelete = createAsyncThunk(
  "superCategory/superCategoryDelete",
  async (Id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/superCategory/delete/${Id}`;
      const resp = await axios.delete(url);
      return resp.data;
    } catch (error) {
      // return error.response.data;/
      return thunkAPI.rejectWithValue("SuperCategory Not create");
    }
  }
);
export const superCategoryImages = createAsyncThunk(
  "superCategory/superCategoryImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/superCategory/uploardSuperCategoryImage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("images Not create");
    }
  }
);
const superCategorySlice = createSlice({
  name: "superCategory",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(superCategoryPost.pending, (state) => {
        state.superCategoryLoading = true;
      })
      .addCase(superCategoryPost.fulfilled, (state, action) => {
        state.superCategoryLoading = false;
        state.superCatTotal = [
          ...state.superCatTotal,
          action.payload.supercategory,
        ];
        localStorage.setItem(
          "superCatTotal",
          JSON.stringify(state.superCatTotal)
        );
      })
      .addCase(superCategoryPost.rejected, (state) => {
        state.superCategoryLoading = true;
      })
      .addCase(superCategoryAll.pending, (state) => {
        state.superCategoryLoading = true;
      })
      .addCase(superCategoryAll.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.superCatTotal = action.payload.supercategories;
          localStorage.setItem(
            "superCatTotal",
            JSON.stringify(state.superCatTotal)
          );
        }
        state.isSuperCategoryAvailable = true;
        state.superCategoryLoading = false;
      })
      .addCase(superCategoryAll.rejected, (state) => {
        state.isSuperCategoryAvailable = false;
        state.superCategoryLoading = true;
      })
      .addCase(superCategoryUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.superCatTotal = state.superCatTotal.filter(
            (supercategory) =>
              supercategory._id !== action.payload.supercategory._id
          );
          state.superCatTotal = [
            ...state.superCatTotal,
            action.payload.supercategory,
          ];
          localStorage.setItem(
            "superCatTotal",
            JSON.stringify(state.superCatTotal)
          );
        }
        state.superCategoryLoading = false;
      })
      .addCase(superCategoryUpdate.rejected, (state) => {
        state.superCategoryLoading = true;
      })
      .addCase(superCategoryDelete.pending, (state) => {
        state.delSupercatLoading = true;
      })
      .addCase(superCategoryDelete.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.superCatTotal = state.superCatTotal.filter(
            (supercategory) =>
              supercategory._id !== action.payload.supercategory._id
          );

          localStorage.setItem(
            "superCatTotal",
            JSON.stringify(state.superCatTotal)
          );
        }
        state.delSupercatLoading = false;
      })
      .addCase(superCategoryDelete.rejected, (state) => {
        state.delSupercatLoading = true;
      });
  },
});

export default superCategorySlice.reducer;
